import { Typography } from '@mui/material'
import {
  FooterAward,
  FooterAwardActive,
  FooterHome,
  FooterHomeActive,
  FooterPresent,
  FooterPresentActive,
  FooterStar,
  FooterStarActive,
} from '../utils/icons'

export const SERVICE = {
  OFF: 'off',
  ON: 'on',
}

export const IP_COUNTRY_DETECTION_API = 'https://api.country.is/'

export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const AUTH_FLOW_TYPE = {
  BASE: 'BASE',
  SINGLE_STEP: 'SINGLE_STEP',
}

export const SUPPORT_MAIL = 'support@playbackrewards.com'

export const LOCAL_STORAGE = {
  JWT_TOKEN: 'jwtToken',
  TUTORIAL_BEGIN: 'tutorial-begin',
  ONBOARDING_INTERRUPT: 'onboarding-interrupt',
  DEVICE_INFO: 'device_info',
  OPEN_MMP_LINK: 'open_mmp_link',
  OFFER_REWARDS_EXPLAINER_GAME: 'offer_rewards_explainer_game',
  ONBOARDING_MMP: 'onboarding_mmp',
  IP_ADDRESS: 'ip_address',
  APP_CHECK_TOKEN: 'app_check_token',
  GIFT_CARD_ID: 'gift-card-id',
  GIFT_CARD_DENOMINATION: 'gift-card-denomination',
  REFERRAL_ID: 'referralId',
  LAST_ACTIVITY: 'last-activity',
  LAST_CLICKED_MMP_EVENT_TASK: 'last-clicked-mmp-event-task',
  DISABLE_GEO_DATA_API: 'disable_geo_data_api',
}

export const SEARCH_PARAMS = {
  FAQ_SHOWN: 'shown',
  DONT_HAVE: 'dont-have',
}

export const DONT_HAVE_ACCOUNT_TEXT =
  "We currently only support Gmail integration. Enter your email below and we'll let you know as soon as we support your email provider."

export const HOME_HOW_TO_EARN_TITLES = [
  {
    text: 'Earn points with every game purchase you would normally make.',
    imageUrl: '/images/pig-1.png',
  },
  { text: 'Earn bonus points for trying out new games.', imageUrl: '/images/pig-2.png' },
  { text: 'Turn your points into gift cards.', imageUrl: '/images/pig-3.png' },
]

export const WHITELIST_MESSAGES = {
  SUCCESS: {
    title: 'Success',
    text: 'Thank you for your interest. We will reach out as soon as a spot is available!',
  },
  ERROR: {
    title: 'Already signed up',
    text: 'That email has already registered. We will reach out as soon as a spot is available!',
  },
  SUCCESS_SIGN_UP: {
    title: 'Thank you!',
    text: 'We’ll email you as soon as Playback is available for your email provider!',
  },
}

export const GMAIL_CONNECTION_STATUS = {
  CONNTECTED: 'Connected',
  DISCONNECTED: 'Disconnected',
}

export const CURRENCY_SYMBOL = {
  USD: '$',
  EUR: '€',
  GPB: '£',
}

export const BOTTOM_NAVIGATION_TABS = [
  {
    route: '/games',
    Icon: FooterHome,
    ActiveIcon: FooterHomeActive,
    title: 'Games',
  },
  { route: '/quests', Icon: FooterAward, ActiveIcon: FooterAwardActive, title: 'Quests' },
  {
    route: '/library',
    Icon: FooterStar,
    ActiveIcon: FooterStarActive,
    title: 'Library',
  },
  {
    route: '/rewards',
    Icon: FooterPresent,
    ActiveIcon: FooterPresentActive,
    title: 'Rewards',
  },
]

export const POINTS_MULTIPLIER = (denomination) => denomination * 1000
export const PIGGY_BANKS_MULTIPLIER = (denomination) => denomination * 100

export const MAIN_PAGE = '/games'

export const MAX_ATTEMPTS_AMOUNT = 2

export const CREATE_ACCOUNT_SLIDER_ITEMS = [
  {
    image: '/images/createAccountSliderImage2.png',
    title: 'Earn Rewards!',
    text: 'Collect points with every purchase from your favorite games.',
  },
  {
    image: '/images/createAccountSliderImage3.png',
    title: 'Discover New Games!',
    text: 'Earn bonus points when you try new games.',
  },
  {
    image: '/images/createAccountSliderImage4.png',
    title: 'Redeem Your Rewards!',
    text: 'Turn your points into gift cards and more!',
  },
]

export const AUTHORIZATION_STATE = {
  LOADED: 0,
  DONT_HAVE: 1,
  CREATE_ACCOUNT: 2,
}

export const PLATFORMS = {
  ANDROID: 'Android',
  IOS: 'iOS',
}
export const PLATFORMS_ARRAY = Object.values(PLATFORMS)

export const AVAILABLE_COUNTRIES = {
  Australia: 'AU',
  Canada: 'CA',
  Ukraine: 'UA',
  'United States': 'US',
}

export const AVAILABLE_COUNTRIES_ABBRV = ['AU', 'CA', 'UA', 'US']

export const FAQ_ITEMS = [
  {
    title: 'How does Playback make money?',
    text: (
      <p>
        Game publishers give us money for each new player we send to their games. Also, sometimes
        game publishers pay Playback to run promotional campaigns on the platform. We never charge
        players anything for using Playback and don’t make money directly from our members, ever.
      </p>
    ),
    shown: false,
  },
  {
    title: 'Who is eligible to sign up for Playback?',
    text: (
      <p>
        Due to privacy and legal limitations our users need to be 18 years or older. Also, some
        countries have restrictions on whether their citizens can earn gift cards and real life
        rewards from our service.
      </p>
    ),
    shown: false,
  },
  {
    title: 'What is an "activated offer"?',
    text: (
      <>
        <p>
          All game offers in the featured games list must be activated by tapping the “Try game”
          button in Playback in order to qualify for the special rewards related to those offers.
        </p>
        <br />
        <p>
          You activate an offer by tapping the "Activate & Play" button on any feature game's
          details page. Once you tap that button, the timer starts for that particular offer. Any
          purchases made in that game during before the timer expires, will be rewarded with the
          special rewards related to that particular offer. Once the timer expires, the rewards that
          you will get for purchases in that game return to the standard rewards amount (100 coins
          for every $1USD spent).
        </p>
      </>
    ),
    shown: false,
  },
  {
    title: 'How do I earn coins and piggy banks?',
    text: (
      <>
        <p>
          You earn coins and piggy banks by making purchases in mobile games. Qualifying purchases
          must have receipts from the Apple App Store or Google Play Store that go to the Gmail
          account that you used to create your Playback Rewards account.
        </p>
        <br />
        <p>
          <strong>Featured Games</strong>
        </p>
        <p>
          The games found on the "games" tab are featured games. These are games that we've
          partnered with to give you extra bonus rewards. With each featured game that you've
          activated the special offer for, you can earn both coins and piggy banks with each
          recognized purchase. Each activated offer will have it's own listed rate of coins and
          piggy banks earned per $1 USD spent in that game, often up to a certain amount of spend.
        </p>
        <br />
        <p>
          <strong>Favorite Games</strong>
        </p>
        <p>
          The games found on the "library" tab are your favorite games. These games are ones that
          you have previous receipts for.
        </p>
        <br />
        <p>
          Without an activated offer, the standard rate for purchases in any mobile game is 100
          coins for every $1 USD spent.
        </p>
      </>
    ),
    shown: false,
  },
  {
    title: 'What are coins and piggy banks worth?',
    text: (
      <p>
        You need both coins and piggy banks to redeem gift cards. 1,000 coins and 100 piggy banks is
        worth $1 USD in gift cards.
      </p>
    ),
    shown: false,
  },
  {
    title: 'How long does it take to get a gift card?',
    text: (
      <p>
        Receiving a gift card can take up to 24 hours due to additional security and fraud measures
        we do to protect Playback accounts. Once we process your gift card, you'll receive an email
        with details on how to redeem the specific gift card. All gift cards are digital.
      </p>
    ),
    shown: false,
  },
  {
    title: 'What information do you use from my email?',
    text: (
      <p>
        Our access to your email information is very limited. We detect only Google Play and Apple
        App Store receipt emails and extract specific key data from those emails, like game name,
        purchase amount, and purchase time to make sure that we can credit your playback rewards
        account with the appropriate coins and piggy banks.
      </p>
    ),
    shown: false,
  },
  {
    title: 'Contact Us',
    text: (
      <p>
        If you have any questions, concerns, or feedback please email us at{' '}
        <a href={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a>
      </p>
    ),
    shown: false,
  },
]

export const GAME_DETAILS_BUTTON_TEXT = 'Play Game'

export const ACTIVATE_OFFER_NOTIFICATION_STATUS = {
  INIT: 'init',
  OFFER_ACTIVATED: 'offer_activated',
  CLAIMED: 'claimed',
}

export const IDENTITY_VERIFICATION_STATUS = {
  PROCESSING: 'processing',
  FINISHED: 'finished',
}

export const INVALID_REFRESH_TOKEN_STATUS = {
  INVALID: 'invalid',
  TEMPORARILY_RESOLVED: 'temporarily_resolved',
}

export const MMP_REPLACE_CONSTS = {
  PUBLISHER_ID: '1001',
  UA_OFFER_SUB_SITE_ID: 2001,
  NON_UA_OFFER_SUB_SITE_ID: 2002,
}

export const MMP_PROVIDERS = {
  NONE: 'NONE',
  APPSFLYER: 'APPSFLYER',
  MOTIVE: 'MOTIVE',
  SINGULAR: 'SINGULAR',
  ADJUST: 'ADJUST',
  BITLAB: 'BITLAB',
  HANG_MY_ADS: 'HANG_MY_ADS',
  FEEDMOB: 'FEEDMOB',
  AD_ACTION: 'AD_ACTION',
  TYR_REWARDS: 'TYR_REWARDS',
}

export const BUTTON_TYPE = {
  SUBMIT: 'submit',
}

export const AGE_MINIMUM_DATE = '1900-01-01'

export const GMAIL_CONNECTION_FAILURE_REASONS = [
  {
    type: 'already_has_token',
    title: 'It seems like you have already given access to our application',
    message:
      'Playback Rewards already has some access but not all. Please try to give access again to finish the registration.',
    buttonText: 'Give access again',
  },
  {
    type: 'connected_email',
    title: "It seems like you've tried to give gmail access to wrong account",
    message: (
      <>
        When requesting access for our application, please use the gmail account that you used at
        registration. Please return to the previous screen and ensure that you are using the right
        account. If it's your email and it used for another user, please contact{' '}
        <a href={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a> as soon as possible!
      </>
    ),
    buttonText: 'Give access again',
  },
  {
    type: 'unexpected_error',
    title: 'Unexpected Error',
    message: `Please contact mailto:${SUPPORT_MAIL} to fix this issue!`,
    buttonText: 'Move back',
  },
]

export const ERROR_MESSAGE = (
  <Typography
    variant="p"
    sx={{
      fontWeight: 400,
      a: {
        color: '#27272A',
        wordBreak: 'break-all',
        whiteSpace: 'normal',
      },
    }}
  >
    An error occurred, please try again or contact{' '}
    <a href={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a> if this issue does not resolve.
  </Typography>
)

export const ERROR_TEXT = {
  connected_email: 'This email is already in use or something went wrong!',
  reconnect_email:
    'Some email accounts still need to be reconnected. Please authorize all connected accounts.',
  already_has_token:
    "You have already grant PlaybackRewards with access to your gmail! Please try again! If it doesn't work try to revoke the access in Google security settings!",
  invalid_access: "You've missed one required access, please try again",
  unsupported_country: 'Your country is not supported by PlaybackRewards!',
  choose_gift_card: 'Temporary error, please choose a new Gift Card.',
  account_frozen: (
    <Typography
      variant="p"
      sx={{
        fontWeight: 400,
        a: {
          color: '#0000FF',
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
        },
      }}
    >
      Your account cannot redeem gift cards until a manual review takes place. You can email{' '}
      <a href="mailto:help@playbackrewards.com">help@playbackrewards.com</a> to ask about the status
      of this manual review.
    </Typography>
  ),
}

export const ERROR_REGEX = {
  account_blocked: /^Account: .* is blocked$/,
  user_not_found: /^User with id: .* is not found!/,
}

export const SUCCESS_TEXT = {
  ADD_SECOND_EMAIL:
    'Email connection successful! All future receipts from this email will automatically earn rewards.',
}

export const OFFER_QUALITY = {
  GOOD: {
    name: 'Good',
    color: '#00AA07',
  },
  GREAT: {
    name: 'Great',
    color: '#0070AE',
  },
  LEGENDARY: {
    name: 'Legendary',
    color: '#B000B4',
  },
  FEATURED: {
    name: 'Featured',
    color: '#F59E0B',
  },
}

export const GAMES_FOOTER_HEIGHT = 70
export const DEFAULT_REWARDS_SLIDE_TIMEOUT = 1000

export const ANALYTICS_EVENT = {
  LOGOUT: {
    eventName: 'logout',
  },
  USER_DATA_EXPORT: {
    eventName: 'user_data_export',
  },
  SELECT_ITEM: {
    OFFER: {
      eventName: 'select_item',
      eventParams: {
        item_list_id: 'main_offer_list',
        item_list_name: 'Main Offer List',
        // TODO: When categories will be added, move item_category from constants
        item_category: 'main list',
        price: 0,
      },
    },
    GIFT_CARD: {
      eventName: 'select_item',
      eventParams: {
        item_list_id: 'gift_card_list',
        item_list_name: 'Gift Card List',
        index: 0,
      },
    },
  },
  IDENTITY_VERIFICATION_FINISH: {
    eventName: 'identity_verification_finish',
  },
  OFFER_ENGAGEMENT: {
    eventName: 'offer_engagement',
  },
  TUTORIAL_BEGIN: {
    eventName: 'tutorial_begin',
  },

  FEATURED_CARD_TRACKING: {
    eventName: 'featured_card_tracking',
  },
  DEVICE_INFO: {
    eventName: 'device_info',
  },
  APP_BOOT: {
    eventName: 'app_boot',
  },
  APP_USAGE_PROMPT: {
    eventName: 'app_usage_prompt',
  },
  OFFER_TILE_VIEW: {
    eventName: 'offer_tile_view',
  },
  OFFER_TILE_CLICK: {
    eventName: 'offer_tile_click',
  },
}

export const ANALYTICS_FRONTEND_EVENT = {
  SET_USER_ID: {
    event: 'set_user_id',
  },
}

export const ANALYTICS_FEATURED_CARD_TRACKING_TYPE = {
  REFERRAL_OFFER: 'referral offer',
  NEW_GAME_OFFER: 'new game offer',
  EXISTING_SPENDER_OFFER: 'existing spender offer',
}

export const ANALYTICS_OFFER_ENGAGEMENT_TYPE = {
  TRY: 'tapped try game',
  PLAY: (day) => `tapped play day ${day}`,
  ACTIVATE: 'tapped activate',
  MMP_EVENTS: (taskDescription) => `tapped ${taskDescription}`,
}

export const ANALYTICS_EMPTY_VALUE = 'none'

export const USER_PLATFORM = {
  IOS: 'iOS',
  ANDROID: 'Android',
  DESKTOP: 'Desktop',
}

export const COLOR = {
  GRAY: '#535353',
  GOLDEN: '#EB9800',
  RED: '#DC2626',
}

export const TIMER_EXPIRED = 'expired'

export const CLAIM_SPECIAL_REWARD_REASON = {
  SIGN_UP_REFERRER: 'SIGN_UP_REFERRER',
  SIGN_UP_REFERRED: 'SIGN_UP_REFERRED',
  CLAIM_REWARD_REFERRER: 'CLAIM_REWARD_REFERRER',
  CLAIM_REWARD_REFERRED: 'CLAIM_REWARD_REFERRED',
  INSTALL_GAME: 'INSTALL_GAME',
  PLAY_GAME: 'PLAY_GAME',
  WELCOME: 'WELCOME',
  TRY_FIRST_GAME: 'TRY_FIRST_GAME',
  OFFER_SPEND_BONUS: 'OFFER_SPEND_BONUS',
  RECEIPT: 'RECEIPT',
  PLAYTIME: 'PLAYTIME',
  MMP_EVENT: 'MMP_EVENT',
}

export const PIGS_DIVIDER = 2
export const COINS_DIVIDER = 100

export const PRIVACY_POLICY_LINK = 'https://playbackrewards.com/privacy-policy'
export const TERMS_OF_SERVICE_LINK = 'https://playbackrewards.com/terms-of-service'

export const TIMER_COLORS = {
  ACTIVE: COLOR.GOLDEN,
  INACTIVE: COLOR.GRAY,
  ENDED: COLOR.RED,
}

export const REFERRAL_SUBDOMAIN = {
  development: (referralId) => `r.pbr.gg/r/refer_local/${referralId}`,
  staging: (referralId) => `r.pbr.gg/r/refer_staging/${referralId}`,
  'release-candidate': (referralId) => `r.pbr.gg/r/refer_staging/${referralId}`,
  production: (referralId) => `r.pbr.gg/r/refer/${referralId}`,
}

export const REACT_NATIVE_STATUS = {
  LOGIN: 'LOGIN',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  CONNECT_SECOND: 'CONNECT_SECOND',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  FB_EVENT_INSTALL_GAME: 'FB_EVENT_INSTALL_GAME',
  TRY_GAME: 'TRY_GAME',
  BOOTSTRAP: 'BOOTSTRAP',
  OPEN_GAME: 'OPEN_GAME',
  SET_USER: 'SET_USER',
  SEND_EVENT_ANALYTIC: 'SEND_EVENT_ANALYTIC',
  APP_BOOT: 'APP_BOOT',
  SEND_EVENT_APPSFLYER: 'SEND_EVENT_APPSFLYER',
  APP_BOOT_TRACKING: 'APP_BOOT_TRACKING',
  GET_DEVICE_INFO: 'GET_DEVICE_INFO',
  CAMERA_PERMISSION: 'CAMERA_PERMISSION',
  STRIPE_IDENTITY: 'STRIPE_IDENTITY',
  NAVIGATE: 'NAVIGATE',
  OPEN_APP_USAGE_PERMISSIONS: 'OPEN_APP_USAGE_PERMISSIONS',
  UPDATE_USAGE_ACCESS: 'UPDATE_USAGE_ACCESS',
  APP_USAGE_SENT: 'APP_USAGE_SENT',
  START_PLAYTIME_TRACKING_IN_FOREGROUND: 'START_PLAYTIME_TRACKING_IN_FOREGROUND',
  LOGOUT_USER: 'LOGOUT_USER',
  REQUEST_MY_DATA: 'REQUEST_MY_DATA',
  RELOAD_WEB_VIEW: 'RELOAD_WEB_VIEW',
}

export const FLY_COINS_MAX_COUNT = 20

export const INSTALL_VALIDATING_MINUTES = 5
export const PLAY_DAY_VALIDATING_MINUTES = 1.5
export const MILLISECONDS_IN_MINUTE = 60000

export const ONBOARDING_STEP_DIRECTION = {
  true: 'next',
  false: 'previous',
}

export const ONBOARDING_STEP = {
  START: 'start',
  COINS_EXPLAINER: 'coins_explainer',
  PIGGY_EXPLAINER: 'piggy_explainer',
  MOVE_TO_GIFT_CARDS: 'move_to_gift_cards',
  GIFT_CARDS_EXPLAINER: 'gift_cards_explainer',
  MOVE_TO_GAMES: 'move_to_games',
  GAMES_EXPLAINER: 'games_explainer',
  TRY_GAME: 'try_game',
  IOS_ACCESS: 'ios_access',
  DOWNLOAD_CONFIRMATION: 'download_confirmation',
  COMPLETE: 'complete',
  TAP_PRIORITIZED_OFFER: 'tap_prioritized_offer',
  ACTIVATE_OFFER: 'activate_offer',
  OFFER_REWARDS_EXPLAINER: 'offer_rewards_explainer',
}

export const CLICK_CARD_ONBOARDING_STEPS = [
  ONBOARDING_STEP.GAMES_EXPLAINER,
  ONBOARDING_STEP.TAP_PRIORITIZED_OFFER,
]

export const ONBOARDING_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
}

export const ONBOARDING_REPLACEMENT_TEXT_NAME = {
  OFFER_REWARDS_EXPLAINER_GAME: 'OFFER_REWARDS_EXPLAINER_GAME',
}

export const ONBOARDING_STEPS = [
  {
    name: ONBOARDING_STEP.START,
    path: '/games',
    title: <Typography variant="title">Welcome to Playback!</Typography>,
    content: 'Your email is connected and you’re ready to start earning rewards!',
    button: {
      text: 'LET’S GO!',
    },
  },
  {
    name: ONBOARDING_STEP.COINS_EXPLAINER,
    path: '/games',
    content: 'Now you’ll earn coins with every purchase you make in any mobile game!',
    spotlight: {
      target: '.gamesHeaderInputInner .coin',
      padding: {
        top: 15,
        left: 10,
        right: 25,
        bottom: 15,
      },
    },
    style: {
      position: ONBOARDING_POSITION.BOTTOM,
      deviation: 94,
    },
    button: {
      text: 'GOT IT!',
    },
  },
  {
    name: ONBOARDING_STEP.PIGGY_EXPLAINER,
    path: '/games',
    content: 'Earn piggy banks by playing and spending in Playback Featured Games!',
    spotlight: {
      target: '.gamesHeaderInputInner .pig',
      padding: {
        top: 15,
        left: 10,
        right: 25,
        bottom: 15,
      },
    },
    style: {
      position: ONBOARDING_POSITION.BOTTOM,
      deviation: 82,
    },
    button: {
      text: 'GREAT!',
    },
  },
  {
    name: ONBOARDING_STEP.MOVE_TO_GIFT_CARDS,
    path: '/games',
    nextPath: '/rewards',
    title: (
      <div className="onboardingMoveToGiftCards">
        <img className="onboardingMoveToGiftCardsCoin" src="/images/coin.png" /> +{' '}
        <img className="onboardingMoveToGiftCardsPig" src="/images/pig.png" /> ={' '}
        <img className="onboardingMoveToGiftCardsGiftCard" src="/images/giftCard.png" />
      </div>
    ),
    content: 'Combine coins and piggy banks to redeem gift cards!',
    spotlight: {
      target: '.gamesFooterInner > a:last-child',
      clickable: true,
      pointer: {
        text: 'Let’s take a look!',
        left: false,
      },
    },
  },
  {
    name: ONBOARDING_STEP.GIFT_CARDS_EXPLAINER,
    path: '/rewards',
    spotlight: {
      target: '.giftCardListTitlesInner',
      padding: {
        top: -10,
        bottom: 265,
      },
      center: true,
    },
    style: {
      position: ONBOARDING_POSITION.BOTTOM,
      deviation: 39,
    },
    content: 'Turn rewards into popular gift cards with options as low as $1!',
    button: {
      text: 'YAY!',
    },
  },
  {
    name: ONBOARDING_STEP.MOVE_TO_GAMES,
    path: '/rewards',
    nextPath: '/games',
    spotlight: {
      target: '.gamesFooterInner > a:first-child',
      clickable: true,
      pointer: {
        left: true,
      },
    },
    content: (
      <>
        Now let’s have some fun and earn rewards!
        <br />
        <br />
        Go here to check out your games
      </>
    ),
    style: {
      position: ONBOARDING_POSITION.TOP,
      deviation: 40,
    },
  },
  {
    name: ONBOARDING_STEP.GAMES_EXPLAINER,
    path: '/games',
    content: 'To get started, pick a featured game you think you’ll enjoy playing!',
    spotlight: {
      target: '.gamesListFeaturedGamesInner',
      clickable: true,
    },
    style: {
      position: ONBOARDING_POSITION.BOTTOM,
      top: 30,
      width: '100%',
    },
    scrollable: true,
  },
  {
    name: ONBOARDING_STEP.TRY_GAME,
    path: '/games/:unifiedAppId',
    spotlight: {
      target: '.gameDetailsSpecialOfferInstallTask',
      clickable: true,
      padding: {
        top: 27,
        left: 16,
        right: 16,
        bottom: 19,
      },
      focus: true,
    },
    style: {
      position: ONBOARDING_POSITION.TOP,
      deviation: 16,
    },
    content: 'To activate offer rewards, tap the "Try Game" button and play!',
    button: {
      text: 'SWEET!',
      back: true,
    },
    placement: 'top',
  },
  { name: ONBOARDING_STEP.IOS_ACCESS, path: '/games/:unifiedAppId' },
  { name: ONBOARDING_STEP.DOWNLOAD_CONFIRMATION, path: '/games/:unifiedAppId' },
  {
    name: ONBOARDING_STEP.TAP_PRIORITIZED_OFFER,
    path: '/games',
    spotlight: {
      target: '.offerCardInner.featured',
      padding: {
        top: 36,
        bottom: 20,
        left: 16,
        right: 16,
      },
      clickable: true,
    },
    style: {
      position: ONBOARDING_POSITION.BOTTOM,
      deviation: 21,
    },
    content: 'Tap the offer above to earn rewards on your next purchase!',
  },
  {
    name: ONBOARDING_STEP.ACTIVATE_OFFER,
    path: '/games/:unifiedAppId',
    spotlight: {
      target: '.gameDetailsSpecialOfferInstallTask',
      padding: {
        top: 1000,
        bottom: 10,
        left: 16,
        right: 16,
      },
      clickable: true,
      focus: true,
    },
    style: {
      position: ONBOARDING_POSITION.BOTTOM,
      deviation: -979,
    },
    content: (
      <div className="onboardingActivateOfferContent">
        <div className="onboardingActivateOfferArrows">
          <img src="/images/arrowDown.png" className="onboardingPointerArrow" />
          <img src="/images/arrowDown.png" className="onboardingPointerArrow" />
        </div>
        <Typography variant="h1">
          To activate offer rewards, tap the Activate button here.
        </Typography>
      </div>
    ),
  },
  {
    name: ONBOARDING_STEP.OFFER_REWARDS_EXPLAINER,
    path: '/games/:unifiedAppId',
    spotlight: {
      target: '.gameDetailsSpecialOfferSpendingRewards',
      padding: {
        top: 40,
        bottom: 300,
        left: 16,
        right: 16,
      },
      clickable: true,
      focus: {
        top: 400,
      },
    },
    style: {
      position: ONBOARDING_POSITION.TOP,
      deviation: 58,
    },
    content: (
      <>
        Now for every $1 you spend in{' '}
        {`{${ONBOARDING_REPLACEMENT_TEXT_NAME.OFFER_REWARDS_EXPLAINER_GAME}}`}, you’ll earn{' '}
        <img src="/images/coin.png" /> & <img src="/images/pig.png" /> !
      </>
    ),
    button: {
      text: 'Let’s Go!',
    },
  },
]

//TODO: Reformat VERIFIED and UNVERIFIED statuses to the uppercase
export const VERIFICATION_STATUS = {
  UNVERIFIED: 'Unverified',
  VERIFIED: 'Verified',
  STRIPE_REJECTED: 'STRIPE_REJECTED',
  PLAYBACK_DUPLICATE_REJECTED: 'PLAYBACK_DUPLICATE_REJECTED',
  COUNTRY_MISMATCH: 'COUNTRY_MISMATCH',
  COUNTRY_INELIGIBLE: 'COUNTRY_INELIGIBLE',
}

export const STRIPE_VERIFICATION_CONTACT_PARAGRAPH = (
  <p>
    If you believe this identity verification is incorrect or you have any questions, please contact
    Playback support at <a href={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a>.
  </p>
)

export const QUEST_TYPE = {
  COMPLETED: 'completed',
  STARTER: 'starter',
  FLASH: 'flash',
  WEEKLY: 'weekly',
}

export const QUESTS_LIST_ITEM_CONTENT = {
  [QUEST_TYPE.COMPLETED]: {
    title: 'Completed Quests',
  },
  [QUEST_TYPE.FLASH]: {
    title: 'Flash Quests',
  },
  [QUEST_TYPE.STARTER]: {
    title: 'Starter Quests',
  },
  [QUEST_TYPE.WEEKLY]: {
    title: 'Weekly Quests',
  },
}

export const QUEST_PROGRESS_TYPE = {
  MINUTES_PLAYED: 'MINUTES_PLAYED',
  DOLLARS_SPEND: 'DOLLARS_SPEND',
  PLAYBACK_DOLLARS_SPEND: 'PLAYBACK_DOLLARS_SPEND',
  REFERRED_DOLLARS_SPEND: 'REFERRED_DOLLARS_SPEND',
  MULTIPLE_QUESTS: 'MULTIPLE_QUESTS',
}

export const GAMES_PAGE_QUEST_TYPE = {
  COMPLETED: 'completed',
  SUGGESTED: 'suggested',
}

export const REWARD_HISTORY_TYPE = {
  REFER_FRIEND: 'referFriend',
  OFFER: 'offer',
  QUESTS: 'quests',
}

export const ERRORS = {
  LOGIN: 'LOGIN',
  VIDEO_CACHE: 'VIDEO_CACHE',
  CACHE: 'CACHE',
  IMAGE_FETCH: 'IMAGE_FETCH',
  APP_CHECK: 'APP_CHECK',
  IP_COUNTRY_DETECTION: 'IP_COUNTRY_DETECTION',
  IP_COUNTRY_DETECTION_RQ: 'IP_COUNTRY_DETECTION_RQ',
  MMP_LINK: 'MMP_LINK',
  GTM_INIT: 'GTM_INIT',
  LOGOUT_REFRESH: 'LOGOUT_REFRESH',
  AUTH_ERROR: 'AUTH_ERROR',
  TWO_STEP_AUTH_ERROR: 'TWO_STEP_AUTH_ERROR',
  UNIFIED_APP_ID_UNDEFINED: 'UNIFIED_APP_ID_UNDEFINED',
  TWENTYFOUR_METRICS_TRACK_IMPRESSION: 'TWENTYFOUR_METRICS_TRACK_IMPRESSION',
  TWENTYFOUR_METRICS_TRACK_IMPRESSION_API_CALL: 'TWENTYFOUR_METRICS_TRACK_IMPRESSION_API_CALL',
  TWENTYFOUR_METRICS_TRACK_CLICK: 'TWENTYFOUR_METRICS_TRACK_CLICK',
  TWENTYFOUR_METRICS_TRACK_CLICK_API_CALL: 'TWENTYFOUR_METRICS_TRACK_CLICK_API_CALL',
  REACT_NATIVE_POST_MESSAGE: 'REACT_NATIVE_POST_MESSAGE',
}

export const OFFER_TYPE = {
  PAY_PER_NEW_PURCHASER: 'PAY_PER_NEW_PURCHASER',
  PAY_PER_INSTALL: 'PAY_PER_INSTALL',
  SEGMENT_OFFER: 'SEGMENT_OFFER',
  REWARDED_PLAYTIME: 'REWARDED_PLAYTIME',
  MMP_EVENTS: 'MMP_EVENTS',
  GENERIC_OFFER: 'GENERIC_OFFER',
}

export const APP_USAGE_PROMPT_RESULT = {
  CLICKED_TO_PERMISSIONS: 'CLICKED_TO_PERMISSIONS',
  CLICKED_SKIP: 'CLICKED_SKIP',
}

export const APP_PLATFORM = {
  WEB: 'WEB',
  NATIVE_APP: 'NATIVE_APP',
}

export const CUSTOM_EVENTS = {
  INIT_APP_CHECK: 'INIT_APP_CHECK',
}

// Offer type for different display of cards on the main page
export const GAME_CARD_OFFER_TYPE = {
  FEATURED: 'featured',
  ACTIVATED: 'activated',
  DEFAULT: 'default',
}

// States for PiggyBank chest modal to display animations and content based on them
export const PIGGY_BANK_CHEST_MODAL_STATE = {
  START: 'START',
  PROGRESSION: 'PROGRESSION',
  FINISH: 'FINISH',
}

// TODO: This should move into shared code once we have it
export const PLAYBACK_GAME_CATEGORIES_MAPPING = [
  {
    rowName: 'actionRow',
    playbackCategory: 'Action',
    categories: ['game_action', 'GAME_ACTION', 'game_arcade', 'GAME_ARCADE', 7001, '7001'],
    order: 5,
  },
  {
    rowName: 'adventureRow',
    playbackCategory: 'Adventure',
    categories: ['game_adventure', 'GAME_ADVENTURE', 7002, '7002'],
    order: 7,
  },
  {
    rowName: 'casualRow',
    playbackCategory: 'Casual',
    categories: ['game_casual', 'GAME_CASUAL', 7003, '7003'],
    order: 2,
  },
  {
    rowName: 'boardRow',
    playbackCategory: 'Board',
    categories: ['game_board', 'GAME_BOARD', 7004, '7004'],
    order: 9,
  },
  {
    rowName: 'cardRow',
    playbackCategory: 'Card',
    categories: ['game_card', 'GAME_CARD', 7005, '7005'],
    order: 10,
  },
  {
    rowName: 'casinoRow',
    playbackCategory: 'Casino',
    categories: ['game_casino', 'GAME_CASINO', 7006, '7006'],
    order: 6,
  },
  {
    rowName: 'educationalRow',
    playbackCategory: 'Educational',
    categories: ['game_educational', 'GAME_EDUCATIONAL'],
    order: 15,
  },
  {
    rowName: 'familyRow',
    playbackCategory: 'Family',
    categories: [
      'family',
      'family_action',
      'family_braingames',
      'family_create',
      'family_education',
      'family_musicvideo',
      'family_pretend',
      7009,
      '7009',
    ],
    order: 17,
  },
  {
    rowName: 'musicRow',
    playbackCategory: 'Music',
    categories: ['game_music', 'GAME_MUSIC', 7011, '7011'],
    order: 12,
  },
  {
    rowName: 'puzzleRow',
    playbackCategory: 'Puzzle',
    categories: ['game_puzzle', 'GAME_PUZZLE', 7012, '7012'],
    order: 3,
  },
  {
    rowName: 'racingRow',
    playbackCategory: 'Racing',
    categories: ['game_racing', 'GAME_RACING', 7013, '7013'],
    order: 13,
  },
  {
    rowName: 'roleplayRow',
    playbackCategory: 'Role Playing',
    categories: ['game_role_playing', 'GAME_ROLE_PLAYING', 7014, '7014'],
    order: 1,
  },
  {
    rowName: 'simulationRow',
    playbackCategory: 'Simulation',
    categories: ['game_simulation', 'GAME_SIMULATION', 7015, '7015'],
    order: 8,
  },
  {
    rowName: 'sportsRow',
    playbackCategory: 'Sports',
    categories: ['game_sports', 'GAME_SPORTS', 7016, '7016'],
    order: 11,
  },
  {
    rowName: 'strategyRow',
    playbackCategory: 'Strategy',
    categories: ['game_strategy', 'GAME_STRATEGY', 7017, '7017'],
    order: 4,
  },
  {
    rowName: 'triviaRow',
    playbackCategory: 'Trivia',
    categories: ['game_trivia', 'GAME_TRIVIA', 7018, '7018'],
    order: 16,
  },
  {
    rowName: 'wordRow',
    playbackCategory: 'Word',
    categories: ['game_word', 'GAME_WORD', 7019, '7019'],
    order: 14,
  },
]
