import React, { useContext, useState } from 'react'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import toast from 'react-hot-toast'

import ValueFrame from '../../../../components/valueFrame'
import BottomButton from '../../../../components/common/BottomButton'
import ProgressBar from '../../../../components/ProgressBar'
import { QUEST_PROGRESS_TYPE, QUEST_TYPE } from '../../../../constants'
import useTimer from '../../../../hooks/useTimer'
import Timer from '../../../../components/timer'
import { claimQuestReward } from '../../../../services/questsService'
import AuthContext from '../../../../context/AuthContext'
import { checkDateIsLessToday, formatDate } from '../../../../utils/utils'
import { handleHeaderCounter, setHeaderCount } from '../../../../utils/animations'
import { Clock } from '../../../../utils/icons'
import { getUserById } from '../../../../services/userService'

const QuestsListItem = ({ quest, type, onClaim }) => {
  const { user, updateUserData } = useContext(AuthContext)
  const navigate = useNavigate()

  const [isClaiming, setIsClaiming] = useState(false)
  const [coinsFly, setCoinsFly] = useState(false)
  const [swiping, setSwiping] = useState(false)
  const [hidden, setHidden] = useState(false)

  const isClaimable = type === QUEST_TYPE.COMPLETED

  const startTimestamp =
    quest.isShownLocked && !checkDateIsLessToday(quest.startedAt) ? quest.startedAt : null
  const expirationTimestamp =
    type !== QUEST_TYPE.WEEKLY && !startTimestamp && !isClaimable ? quest.expiredAt : null

  const timer = useTimer(expirationTimestamp, ['userQuests'])
  const startTimer = useTimer(startTimestamp, ['userQuests'])

  const handleClaim = () => {
    setCoinsFly(true)
    setIsClaiming(true)

    handleHeaderCounter(false, quest.coinsReward)
    handleHeaderCounter(true, quest.piggyReward)

    claimQuestReward(quest.id)
      .then(({ updatedUser }) => updateUserData(updatedUser))
      .catch((error) => {
        if (
          error.response.data.message ===
          'Error with quest claim transaction: QUEST_ERROR: Reward for this quest is already claimed!'
        ) {
          toast.error('Reward for this quest is already claimed!')
        }
        getUserById(user.id)
          .then((updatedUser) => {
            updateUserData(updatedUser)
            setHeaderCount(updatedUser.piggyBanks, updatedUser.points)
          })
          .catch(() => toast.error('Unable to fetch user details.'))
      })

    setTimeout(() => {
      setSwiping(true)
      setTimeout(() => {
        setHidden(true)
        onClaim && onClaim()
        setIsClaiming(false)
      }, 1000)
    }, 1800)
  }

  const handleNavigate = () => {
    navigate(quest.CTA.link)
  }

  const getProgressText = (quest) => {
    if (!quest.progressMeter) {
      return
    }

    const progressType =
      quest.progressMeter.type === QUEST_PROGRESS_TYPE.MINUTES_PLAYED &&
      quest.progressMeter.offersNeededToComplete > 1
        ? QUEST_PROGRESS_TYPE.MULTIPLE_QUESTS
        : quest.progressMeter.type

    switch (progressType) {
      case QUEST_PROGRESS_TYPE.DOLLARS_SPEND:
      case QUEST_PROGRESS_TYPE.PLAYBACK_DOLLARS_SPEND:
      case QUEST_PROGRESS_TYPE.REFERRED_DOLLARS_SPEND:
        return {
          progress: (quest.progressMeter.trackedValue * 100) / quest.progressMeter.value,
          text: `$${quest.progressMeter.trackedValue}/$${quest.progressMeter.value} spent`,
        }
      case QUEST_PROGRESS_TYPE.MINUTES_PLAYED: {
        const targetValue = quest.progressMeter.value

        const trackedValues = Object.values(quest.progressMeter.rawTrackedValue)
        let progressTime = trackedValues.length
          ? Math.floor(_.maxBy(trackedValues, (value) => value.value).value / 60)
          : 0

        //Timer playtime tracking
        if (quest.completedAt) {
          const trackedAtTime = quest.completedAt._seconds * 1000
          const minutesDifference = Math.ceil((trackedAtTime - new Date().getTime()) / (60 * 1000))

          progressTime =
            minutesDifference < 0
              ? quest.progressMeter.value
              : quest.progressMeter.value - minutesDifference
        }

        return {
          progress: (progressTime * 100) / targetValue,
          text: `${progressTime}/${targetValue} min`,
        }
      }
      case QUEST_PROGRESS_TYPE.MULTIPLE_QUESTS: {
        const completedQuestsAmount = Object.values(quest.progressMeter.rawTrackedValue).filter(
          (trackedValue) =>
            trackedValue.completedAt &&
            new Date(trackedValue.completedAt._seconds * 1000) < new Date()
        ).length

        return {
          progress: (completedQuestsAmount * 100) / quest.progressMeter.offersNeededToComplete,
          text: `${completedQuestsAmount}/${quest.progressMeter.offersNeededToComplete} games`,
        }
      }
    }
  }
  const progressBar = getProgressText(quest)

  return (
    <div className={`questsListItemInner ${swiping ? 'alertSwipe' : ''} ${hidden ? 'hidden' : ''}`}>
      <div className="questsListItemHeader">
        <Typography variant="h4" sx={styles.title}>
          {quest.title}
        </Typography>
        {expirationTimestamp && (
          <Timer isActive={false} time={timer} customClassName="questsListItemTimer" title="" />
        )}
        {isClaimable && (
          <Typography variant="small" className="questsListItemCompletedDate">
            {formatDate(quest.completedAt)}
          </Typography>
        )}
      </div>
      <div className="questsListItem">
        <img className="questsListItemImage" src={quest.imageUrl} />
        <div className="questsListItemContent">
          <Typography variant="h4" sx={styles.text}>
            {quest.subtitle}
          </Typography>
          <div className="questsListItemValues">
            {quest.piggyReward !== 0 && (
              <ValueFrame value={quest.piggyReward} isPig={true} coinsFly={coinsFly} />
            )}
            {quest.coinsReward !== 0 && (
              <ValueFrame value={quest.coinsReward} isModal={false} coinsFly={coinsFly} />
            )}
          </div>
        </div>
      </div>
      <div className={`questsListItemContent ${startTimestamp ? 'locked' : ''}`}>
        {startTimestamp && (
          <div className="questsListItemLocked">
            <Typography variant="h4" sx={styles.lockedText}>
              <Clock />
              <br />
              New Quest unlocks in:
              <br />
              {startTimer}
            </Typography>
          </div>
        )}
        {quest.progressMeter && (
          <div className="questsListItemProgress">
            <ProgressBar
              fillerType={
                quest.completedAt && checkDateIsLessToday(quest.completedAt) ? 'completed' : 'gold'
              }
              completed={progressBar.progress}
              style={styles.progress}
            />
            <Typography variant="small" sx={styles.progressValue}>
              {progressBar.text}
            </Typography>
          </div>
        )}
        {isClaimable && (
          <BottomButton style={styles.claimButton} handleClick={handleClaim} disabled={isClaiming}>
            Claim!
          </BottomButton>
        )}
        {quest.CTA && !isClaimable && (
          <BottomButton style={styles.button} handleClick={handleNavigate}>
            {quest.CTA.text}
          </BottomButton>
        )}
      </div>
    </div>
  )
}

const styles = {
  title: {
    fontWeight: 700,
    color: '#1F1F1F',
  },
  text: {
    fontWeight: 400,
    color: '#636874',
  },
  claimButton: {
    padding: '10px 0',
    background:
      'linear-gradient(168.92deg, #ACFFA5 6.85%, #04DF90 90.71%) padding-box, linear-gradient(93.2deg, #04DF90 16.28%, #ACFFA5 92.47%) border-box',
    '&:hover': {
      background:
        'linear-gradient(270deg, #a4fea4 4.52%, #12e292 92.66%) padding-box, linear-gradient(93.2deg, #04DF90 16.28%, #ACFFA5 92.47%) border-box',
      boxShadow: 'none',
    },
  },
  button: {
    padding: '10px 0',
  },
  progress: {
    border: '1px solid #FFC11F66',
    boxShadow: '2px 4px 12px 0px #FF962B1F',
    padding: '1px',
  },
  progressValue: {
    fontWeight: 700,
    color: '#3C4249',
    span: {
      fontWeight: 400,
    },
  },
  piggyValue: {
    background:
      'linear-gradient(#fff9f3,#fff9f3) padding-box,linear-gradient(#ff6492, #FF6492) border-box',
  },
  coinsValue: {
    background:
      'linear-gradient(#fff9f3,#fff9f3) padding-box,linear-gradient(#ffa70b, #ffa70b) border-box',
  },
  lockedText: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: 400,
    svg: {
      path: {
        fill: '#FFFFFF',
      },
    },
  },
}

export default QuestsListItem
