import { Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { useQueryClient } from 'react-query'
import {
  convertPlainObjectFieldsToFirestoreTypes,
  PlaytimeBatchInfo,
} from '@playback-rewards/shared-libraries'

//assets
import { checkIfGenericPlaytimeMMPOffer } from 'utils/genericOffer'
import GamePad from '../../../../assets/offers/gamepad.png'
import Cart from '../../../../assets/offers/cart.png'
//others
import ActivatedOfferTag from '../../../../components/ActivatedOfferTag'
import BottomButton from '../../../../components/common/BottomButton'
import OfferTask from '../../../../components/offerTask'
import OfferCardTimer from '../../../../components/timer'
import {
  CLAIM_SPECIAL_REWARD_REASON,
  LOCAL_STORAGE,
  MILLISECONDS_IN_MINUTE,
  OFFER_TYPE,
  PLAY_DAY_VALIDATING_MINUTES,
  TIMER_EXPIRED,
} from '../../../../constants'
import AuthContext from '../../../../context/AuthContext'
import useTimer from '../../../../hooks/useTimer'
import { BackArrow, CheckMarkCircle, Info } from '../../../../utils/icons'
import {
  checkDateIsLessToday,
  checkDayVisible,
  checkPlaytimeVisible,
  getDayExpirationTimestamp,
  sumFloat,
} from '../../../../utils/utils'
import GenericOffer from './components/GenericOffer'
import { Button, TaskActionButton } from './components/Button'
import ValidatingModalContent from './components/ModalContent/Validating'
import OfferRewardProgress from './components/RewardProgress'
import OfferSpendingRewards from './components/SpendingRewards'
import InstallToUnlock from './components/InstallToUnlock'

import './index.css'

const SpecialOffer = ({
  offer,
  game,
  isActivated = false,
  handleTryGame,
  validating,
  handleInstallGame,
  handlePlayGame,
  handleItemReward,
  isLoading,
  handleClaimUnclaimedRewards,
  showModal,
}) => {
  const queryCLient = useQueryClient()
  const genericOffersKey = useRef(Date.now())
  const [additionalPiggies, setAdditionalPiggies] = useState(0)
  const [visibleDays, setVisibleDays] = useState([])
  const [visiblePlaytimes, setVisiblePlaytimes] = useState([])
  const [unclaimedRewards, setUnclaimedRewards] = useState()
  const [loginRewardsExpanded, setLoginRewardsExpanded] = useState(false)
  const [coins, setCoins] = useState({
    current: 0,
    final: 100,
  })
  const [piggies, setPiggies] = useState({
    current: 0,
    final: 100,
  })
  const [aggregatedPlaytime, setAggregatedPlaytime] = useState(null)

  const { user } = useContext(AuthContext)

  const playtimeBatchInfo = useMemo(() => {
    if (!game?.activatedOffer || !game?.activatedOffer?.batchPlaytimeConfig) {
      return
    }
    // TODO: We should convert activatedOffer when we get it from api.
    // But this requries refactoring how we implmented the timestamps.
    // This change is required to support npm shared package
    const activateOfferCopy = structuredClone(game.activatedOffer)
    convertPlainObjectFieldsToFirestoreTypes(activateOfferCopy)
    return new PlaytimeBatchInfo(activateOfferCopy)
  }, [game?.activatedOffer])

  const handleShowValidatingModal = (install) =>
    showModal({
      title: 'Validating Actions',
      text: <ValidatingModalContent install={install} />,
      titleVariant: 'title',
      isCustomText: true,
      actionButtons: <></>,
      closeButton: true,
    })

  useEffect(() => {
    if (!isLoading) {
      let finalCoins = offer.eligibleSpendAmount * offer.coinsPerDollar
      let finalPiggies = offer.eligibleSpendAmount * offer.piggyPerDollar

      let earnedCoins = 0,
        earnedPiggy = 0,
        price = 0

      if (game.unclaimedRewardsForOffer) {
        game.unclaimedRewardsForOffer?.map((reward) => {
          earnedCoins = sumFloat(earnedCoins, reward.earnedCoins)
          earnedPiggy = sumFloat(earnedPiggy, reward.earnedPiggy)

          if (
            !reward.isOfferPurchaseAdjustment ||
            (game.unclaimedRewardsForOffer.length === 1 && reward.isOfferPurchaseAdjustment)
          ) {
            price = sumFloat(price, reward.price)
          }
        })

        setUnclaimedRewards({
          earnedCoins,
          earnedPiggy,
          price,
        })
      }
      // Display zero when:
      // 1. The offer is not activated.
      // 2. The difference is negative due to a partial reward when install is not attributed.
      let currentCoins = offer.earnedCoins ? offer.earnedCoins - earnedCoins : 0
      let currentPiggies = offer.earnedPiggyBanks ? offer.earnedPiggyBanks - earnedPiggy : 0

      if (game.specialOfferDetails?.isUAOffer) {
        finalCoins = sumFloat(finalCoins, game.specialOfferDetails.installRewards?.coins || 0)
        finalPiggies = sumFloat(
          finalPiggies,
          game.specialOfferDetails.installRewards?.piggyBanks || 0
        )

        if (offer.installGame?.claimed) {
          currentCoins = sumFloat(currentCoins, game.specialOfferDetails.installRewards?.coins || 0)
          currentPiggies = sumFloat(
            currentPiggies,
            game.specialOfferDetails.installRewards?.piggyBanks || 0
          )
        }

        offer.dayXRewards?.forEach((day) => {
          finalCoins = sumFloat(finalCoins, day.rewards?.coins || 0)
          finalPiggies = sumFloat(finalPiggies, day.rewards?.piggyBanks || 0)

          if (day.claimed) {
            currentCoins = sumFloat(currentCoins, day.rewards?.coins || 0)
            currentPiggies = sumFloat(currentPiggies, day.rewards?.piggyBanks || 0)
          }
        })

        offer.playtimeRewards?.forEach((playtime) => {
          finalCoins = sumFloat(finalCoins, playtime.rewards?.coins || 0)
          finalPiggies = sumFloat(finalPiggies, playtime.rewards?.piggyBanks || 0)

          if (playtime.claimed) {
            currentCoins = sumFloat(currentCoins, playtime.rewards?.coins || 0)
            currentPiggies = sumFloat(currentPiggies, playtime.rewards?.piggyBanks || 0)
          }
        })

        offer.mmpEventTasks?.forEach((task) => {
          finalCoins = sumFloat(finalCoins, task.rewards?.coins || 0)
          finalPiggies = sumFloat(finalPiggies, task.rewards?.piggyBanks || 0)

          if (task.claimed) {
            currentCoins = sumFloat(currentCoins, task.rewards?.coins || 0)
            currentPiggies = sumFloat(currentPiggies, task.rewards?.piggyBanks || 0)
          }
        })
      }

      setCoins({
        current: Math.floor(currentCoins),
        final: finalCoins,
      })

      setPiggies({
        current: Math.floor(currentPiggies),
        final: finalPiggies,
      })

      if (game.specialOfferDetails?.isUAOffer) {
        let additionalPiggies = offer.installRewards?.piggyBanks || 0

        offer.dayXRewards?.forEach(({ rewards }) => {
          additionalPiggies = sumFloat(additionalPiggies, rewards.piggyBanks || 0)
        })

        offer.playtimeRewards?.forEach(({ rewards }) => {
          additionalPiggies = sumFloat(additionalPiggies, rewards.piggyBanks || 0)
        })

        const visibleDays = offer.dayXRewards?.filter((day) => checkDayVisible(day))
        setVisibleDays(visibleDays)

        const visiblePlaytimes = offer.playtimeRewards
          ?.filter((playtime, index) => checkPlaytimeVisible(offer, playtime, index))
          .map((playtime, index) => ({ ...playtime, index }))
        setVisiblePlaytimes(visiblePlaytimes)

        setAdditionalPiggies(additionalPiggies)
      }

      if (
        offer.playtimeRewards &&
        (offer.mmpAttributionRequiredForRewards ? offer.isInstallAttributed : true)
      ) {
        const aggregatedReward = {
          minutes: 0,
          earnedCoins: 0,
          earnedPiggy: 0,
        }
        offer.playtimeRewards.forEach((playtime) => {
          if (playtime.completed && !playtime.claimed) {
            aggregatedReward.minutes += playtime.minutes
            aggregatedReward.earnedCoins += playtime.rewards.coins
            aggregatedReward.earnedPiggy += playtime.rewards.piggyBanks
          }
        })
        if (aggregatedReward.minutes > 0) {
          setAggregatedPlaytime(aggregatedReward)
        }
      }

      if (
        offer.offerType === OFFER_TYPE.GENERIC_OFFER &&
        game.unclaimedRewardsForOffer &&
        (offer.mmpAttributionRequiredForRewards ? offer.isInstallAttributed : true)
      ) {
        const aggregatedReward = {
          minutes: 0,
          earnedCoins: 0,
          earnedPiggy: 0,
        }
        game.unclaimedRewardsForOffer
          .filter((reward) => reward.reason === CLAIM_SPECIAL_REWARD_REASON.PLAYTIME)
          .forEach((reward) => {
            aggregatedReward.minutes += reward.minutes
            aggregatedReward.earnedCoins += reward.earnedCoins
            aggregatedReward.earnedPiggy += reward.earnedPiggy
          })
        if (aggregatedReward.minutes > 0) {
          setAggregatedPlaytime(aggregatedReward)
        }
      }
    }
  }, [isLoading])

  const invalidateQueryKeys = [
    'gameDetails',
    game?.gameDetails?.unifiedAppId || game?.gameDetails?.unified_app_id,
    offer.id,
    user.id,
  ]

  const eligibleSpendAmount = game.activatedOffer
    ? game.activatedOffer?.eligibleSpendAmount - game.activatedOffer?.dollarsSpent
    : game.specialOfferDetails.eligibleSpendAmount

  const calculateEligibleSpendAmount = () => {
    if (offer?.eligibleSpendAmount === -1) {
      return <>∞</>
    }

    return <>${eligibleSpendAmount.toFixed(2)}</>
  }

  const expirationTimestamp =
    offer.expirationTimestamp ||
    (offer.endTimestamp && !checkDateIsLessToday(offer.endTimestamp) ? offer.endTimestamp : null)

  const timer = useTimer(expirationTimestamp, invalidateQueryKeys, !!offer.expirationTimestamp)

  const handleClickLoginRewardsArrow = () => setLoginRewardsExpanded((current) => !current)

  const isInstallVisible =
    (offer.mmpAttributionRequiredForRewards && !offer.isInstallAttributed) ||
    (!offer.playtimeRewards &&
      !offer?.installGame?.claimed &&
      (!isActivated || (isActivated && offer?.installGame && 'claimed' in offer.installGame)))

  const checkDayValidating = (day) => {
    return (
      'claimed' in day &&
      day.updatedAt._seconds +
        (process.env.REACT_APP_OFFER_PLAY_DAY_VALIDATING_TIME ||
          PLAY_DAY_VALIDATING_MINUTES * MILLISECONDS_IN_MINUTE) /
          1000 >
        new Date().getTime() / 1000
    )
  }

  const checkDayCompleted = (day) => {
    return (
      !checkDayValidating(day) &&
      'claimed' in day &&
      day.updatedAt._seconds <
        new Date().getTime() / 1000 +
          (process.env.REACT_APP_OFFER_PLAY_DAY_VALIDATING_TIME ||
            PLAY_DAY_VALIDATING_MINUTES * MILLISECONDS_IN_MINUTE)
    )
  }

  const checkPlaytimeCompleted = (playtime) => {
    return (
      (offer.mmpAttributionRequiredForRewards ? offer.isInstallAttributed : true) &&
      (playtime.claimed || playtime.completed)
    )
  }

  const getPlaytimeText = (playtime) => {
    return (
      (offer.mmpAttributionRequiredForRewards ? offer.isInstallAttributed : true) &&
      !playtime.completed &&
      playtime.playedMinutes &&
      playtime.playedMinutes >= 1 &&
      `(${Math.floor(playtime.playedMinutes)} / ${playtime.minutes} min)`
    )
  }

  const isOfferSpendingRewardsVisible =
    timer !== TIMER_EXPIRED &&
    offer.eligibleSpendAmount > 0 &&
    (game.activatedOffer
      ? game.activatedOffer.eligibleSpendAmount - game.activatedOffer.dollarsSpent > 0
      : !isActivated)

  const firstActionablePlaytimeIndex = visiblePlaytimes
    ? visiblePlaytimes.findIndex((playtime) => !playtime.completed)
    : -1
  const firstActionablePlaytime =
    firstActionablePlaytimeIndex > -1 ? visiblePlaytimes[firstActionablePlaytimeIndex] : null

  const playtimeIndexOffset =
    visiblePlaytimes && offer.playtimeRewards
      ? offer.playtimeRewards.length - visiblePlaytimes.length
      : 0

  const checkIfMMPEventTaskUnlocked = (allTasks, task) => {
    return task.previousTaskRequirements.some((requirement) =>
      allTasks.find((event) => event.taskId === requirement && !event.completed)
    )
  }

  const checkIfMMPEventTaskActive = (allTasks, task) => {
    return task.previousTaskRequirements.every((requirement) =>
      allTasks.find((event) => event.taskId === requirement && event.completed)
    )
  }

  const buildMMPEventTaskUnlockingRulesText = (allTasks, task) => {
    if ((!isActivated || validating) && !task.previousTaskRequirements.length) {
      return 'Unlocks after install'
    }

    return (
      <>
        {task.previousTaskRequirements.map((requirement, index) => {
          const eventTask = allTasks.find(
            (event) => event.taskId === requirement && !event.completed
          )
          if (!eventTask) {
            return ''
          }

          return (
            <p key={`requirement ${eventTask.taskId}-${index}`}>
              Unlocks after {eventTask.taskDescription}
            </p>
          )
        })}
      </>
    )
  }

  const genericMMPEventTasks = useMemo(
    () => offer?.tasks?.filter((task) => task.taskType === CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT),
    [offer?.tasks]
  )
  const isGenericPlaytimeMMPOffer = useMemo(() => checkIfGenericPlaytimeMMPOffer(offer), [offer])

  const firstActionableMMPEventTask = isGenericPlaytimeMMPOffer
    ? null
    : offer?.expirationTimestamp &&
      offer.expirationTimestamp._seconds >= new Date().getTime() / 1000
    ? (offer?.mmpEventTasks || genericMMPEventTasks)?.find((task) => !task.completed)
    : null

  const sortMMPEventTasks = (mmpEventTasks) => {
    return _.orderBy(
      mmpEventTasks,
      [(task) => checkIfMMPEventTaskActive(mmpEventTasks, task), (_, index) => index],
      ['desc', 'asc']
    )
  }

  const shouldDisplayBatchedRewards = () => {
    if (!playtimeBatchInfo?.activatedOffer?.batchPlaytimeConfig) {
      return false
    }
    const currTime = new Date()
    const batchIndex = playtimeBatchInfo.getBatchIndexAtTime(currTime)
    const batchEnd = playtimeBatchInfo.getBatchEndTime(batchIndex)
    return batchEnd && batchEnd > currTime
  }
  return (
    <>
      <div className="gameDetailsSpecialOfferInner">
        <div className="gameDetailsSpecialOffer">
          <div className="gameDetailsSpecialOfferHead">
            <Typography variant="h3" sx={styles.title}>
              Special Offer
            </Typography>
            {timer && isActivated && !validating ? (
              <div className="gameDetailsSpecialOfferActivatedInner">
                {timer !== TIMER_EXPIRED && (
                  <ActivatedOfferTag customClassName="gameDetailsSpecialOfferActivatedTag" />
                )}
                <OfferCardTimer
                  expired={timer === TIMER_EXPIRED}
                  time={timer}
                  title=""
                  customClassName="gameDetailsSpecialOfferActivatedTimer"
                />
              </div>
            ) : (
              <div className="gameDetailsSpecialOfferHeadValue">
                <Typography variant="body2">
                  {additionalPiggies ? (
                    <>{additionalPiggies} Free Piggy Banks!</>
                  ) : (
                    <>{game?.specialOfferDetails?.valueStatement}</>
                  )}
                </Typography>
              </div>
            )}
          </div>
          {unclaimedRewards?.price > 0 && (
            <OfferTask
              active={true}
              title={`Spent $${unclaimedRewards.price.toFixed(2)}`}
              coins={unclaimedRewards.earnedCoins}
              icon={<img src={Cart} />}
              piggies={unclaimedRewards.earnedPiggy}
              invalidateQueryKeys={invalidateQueryKeys}
            >
              <BottomButton handleClick={handleClaimUnclaimedRewards} style={styles.button}>
                Claim!
              </BottomButton>
            </OfferTask>
          )}
          {offer.mmpAttributionRequiredForRewards && !offer.isInstallAttributed && (
            <InstallToUnlock showModal={showModal} />
          )}
          {game?.specialOfferDetails?.isUAOffer ? (
            <>
              {isInstallVisible && (
                <OfferTask
                  active={true}
                  icon={<img src={GamePad} />}
                  title={!validating && isActivated ? 'Install Game' : 'Install and open game'}
                  text={
                    offer.mmpAttributionRequiredForRewards && !offer.isInstallAttributed
                      ? 'to unlock additional rewards'
                      : ''
                  }
                  coins={game?.specialOfferDetails?.installRewards?.coins || 0}
                  piggies={game?.specialOfferDetails?.installRewards?.piggyBanks || 0}
                  completed={!validating && isActivated}
                  customClassName="gameDetailsSpecialOfferInstallTask"
                >
                  {validating && <Info onClick={() => handleShowValidatingModal(true)} />}
                  <Button
                    isActivated={isActivated}
                    handleTryGame={handleTryGame}
                    validating={validating}
                    handleItemReward={handleItemReward}
                    isTry={true}
                    isDisabled={isLoading}
                    handlePlayGame={handlePlayGame}
                  />
                </OfferTask>
              )}
              {offer.dayXRewards?.map(
                (day) =>
                  checkDayVisible(day) &&
                  (offer.mmpAttributionRequiredForRewards ? offer.isInstallAttributed : true) &&
                  day.startDate?._seconds < new Date().getTime() / 1000 && (
                    <OfferTask
                      key={`day ${day.day}`}
                      coins={day.rewards?.coins || 0}
                      piggies={day.rewards?.piggyBanks || 0}
                      title={'Play on Day ' + day.day}
                      completed={checkDayCompleted(day)}
                      active={true}
                      icon={<img src={GamePad} />}
                    >
                      {checkDayValidating(day) && (
                        <Info onClick={() => handleShowValidatingModal(false)} />
                      )}
                      <Button
                        isActivated={isActivated}
                        day={day.day}
                        handleItemReward={handleItemReward}
                        handleTryGame={handleTryGame}
                        startDate={day.startDate}
                        claimed={checkDayCompleted(day)}
                        handlePlayGame={handlePlayGame}
                        isDisabled={isLoading}
                        validating={checkDayValidating(day)}
                      />
                    </OfferTask>
                  )
              )}

              {aggregatedPlaytime && (
                <OfferTask
                  coins={aggregatedPlaytime.earnedCoins}
                  piggies={aggregatedPlaytime.earnedPiggy}
                  title={`Played ${aggregatedPlaytime.minutes} ${
                    aggregatedPlaytime.minutes > 1 ? 'minutes' : 'minute'
                  } in the ${game?.gameDetails?.name || 'game'}`}
                  completed={true}
                  active={true}
                  icon={<img src={game?.gameDetails?.icon_url || GamePad} />}
                >
                  <TaskActionButton
                    text={'Claim!'}
                    disableOnClick={true}
                    handleClick={() =>
                      handleItemReward(CLAIM_SPECIAL_REWARD_REASON.PLAYTIME, null, true).then(() =>
                        setAggregatedPlaytime(null)
                      )
                    }
                  />
                </OfferTask>
              )}
              {firstActionablePlaytime &&
                (offer.mmpAttributionRequiredForRewards ? offer.isInstallAttributed : true) && (
                  <OfferTask
                    coins={firstActionablePlaytime.rewards.coins || 0}
                    piggies={firstActionablePlaytime.rewards.piggyBanks || 0}
                    title={
                      firstActionablePlaytime.index === 0 && !offer.mmpAttributionRequiredForRewards
                        ? `Download & Play ${firstActionablePlaytime.minutes} min`
                        : `Play ${firstActionablePlaytime.minutes} min`
                    }
                    completed={checkPlaytimeCompleted(firstActionablePlaytime)}
                    active={true}
                    icon={<img src={GamePad} />}
                    customClassName={
                      firstActionablePlaytime.index === 0 && 'gameDetailsSpecialOfferInstallTask'
                    }
                    text={
                      !firstActionablePlaytime.completed &&
                      firstActionablePlaytime.playedMinutes &&
                      firstActionablePlaytime.playedMinutes >= 1 &&
                      `(${Math.floor(firstActionablePlaytime.playedMinutes)} / ${
                        firstActionablePlaytime.minutes
                      } min)`
                    }
                  >
                    <TaskActionButton
                      text={
                        firstActionablePlaytime.index === 0 && !isActivated ? 'Try Game' : 'Play'
                      }
                      handleClick={
                        firstActionablePlaytime.index === 0 && !isActivated
                          ? handleTryGame
                          : handlePlayGame
                      }
                    />
                  </OfferTask>
                )}

              {(offer.mmpEventTasks || genericMMPEventTasks)?.map(
                (eventTask, index) =>
                  (offer.mmpAttributionRequiredForRewards ? offer.isInstallAttributed : true) &&
                  ((!eventTask.claimed && eventTask.completed && eventTask.isUnlocked) ||
                    firstActionableMMPEventTask === eventTask) && (
                    <OfferTask
                      key={`mmp event ${index}`}
                      coins={eventTask.rewards.coins}
                      piggies={eventTask.rewards.piggyBanks}
                      title={eventTask.taskDescription}
                      completed={eventTask.completed}
                      active={true}
                      validationTimestamp={null}
                      expirationTimestamp={null}
                      icon={<img src={GamePad} />}
                    >
                      {firstActionableMMPEventTask === eventTask ? (
                        <TaskActionButton
                          text="Play"
                          handleClick={() => {
                            localStorage.setItem(
                              LOCAL_STORAGE.LAST_CLICKED_MMP_EVENT_TASK,
                              eventTask.taskDescription
                            )
                            handlePlayGame()
                          }}
                        />
                      ) : (
                        !eventTask.claimed &&
                        eventTask.completed && (
                          <TaskActionButton
                            text={'Claim!'}
                            disableOnClick={true}
                            handleClick={() =>
                              handleItemReward(
                                CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT,
                                null,
                                true,
                                game?.specialOfferDetails?.offerType === OFFER_TYPE.GENERIC_OFFER
                                  ? eventTask.taskId
                                  : index.toString()
                              )
                            }
                          />
                        )
                      )}
                    </OfferTask>
                  )
              )}
            </>
          ) : (
            !isActivated && (
              <OfferTask
                title="Activate Offer"
                active={true}
                customClassName="gameDetailsSpecialOfferInstallTask"
              >
                <BottomButton style={styles.button} handleClick={handleInstallGame}>
                  Activate
                </BottomButton>
              </OfferTask>
            )
          )}
          {visiblePlaytimes == null && offer?.offerType !== OFFER_TYPE.GENERIC_OFFER && (
            <OfferRewardProgress
              coins={coins}
              piggies={piggies}
              disabled={!isActivated || validating}
            />
          )}
          {(visiblePlaytimes?.length > 0 ||
            visibleDays?.length > 0 ||
            offer.mmpEventTasks?.length > 0 ||
            genericMMPEventTasks?.length > 0 ||
            shouldDisplayBatchedRewards() ||
            isOfferSpendingRewardsVisible) &&
            timer !== TIMER_EXPIRED && (
              <>
                {offer.playtimeRewards?.length > 0 && game.specialOfferDetails.isUAOffer && (
                  <div className="gameDetailsSpecialOfferLoginRewards">
                    <div className="gameDetailsSpecialOfferPlaytimeRewardsTitle">
                      <Typography variant="h3">Playtime Rewards</Typography>
                      {!visiblePlaytimes?.length && (
                        <div className="gameDetailsSpecialOfferPlaytimeRewardsCompletedWrap">
                          <div className="gameDetailsSpecialOfferPlaytimeRewardsCompleted">
                            <Typography variant="body2" sx={styles.completed}>
                              <CheckMarkCircle />
                              Completed
                            </Typography>
                          </div>
                        </div>
                      )}
                    </div>

                    {visiblePlaytimes?.length > 0 ? (
                      <>
                        {visiblePlaytimes.map(
                          (playtime, index) =>
                            index < 3 && (
                              <OfferTask
                                disabled={
                                  offer.mmpAttributionRequiredForRewards
                                    ? !offer.isInstallAttributed
                                    : !isActivated ||
                                      (firstActionablePlaytimeIndex > -1 &&
                                        index > firstActionablePlaytimeIndex)
                                }
                                key={`playtime[${index}]`}
                                coins={playtime.rewards?.coins || 0}
                                piggies={playtime.rewards?.piggyBanks || 0}
                                title={
                                  index === 0 &&
                                  playtimeIndexOffset === 0 &&
                                  !offer.mmpAttributionRequiredForRewards
                                    ? `Download & Play ${playtime.minutes} min`
                                    : `Play ${playtime.minutes} min`
                                }
                                completed={checkPlaytimeCompleted(playtime)}
                                active={
                                  offer.mmpAttributionRequiredForRewards
                                    ? offer.isInstallAttributed
                                    : isActivated
                                }
                                validationTimestamp={null}
                                expirationTimestamp={null}
                                invalidateQueryKeys={invalidateQueryKeys}
                                first={index === 0 && !playtime.completed}
                                text={getPlaytimeText(playtime)}
                                icon={<img src={GamePad} />}
                                earn={true}
                              >
                                {
                                  //TODO Figure out how to get the play button to show
                                }
                                <Button
                                  isActivated={isActivated}
                                  day={playtime.day}
                                  handleItemReward={handleItemReward}
                                  handleTryGame={handleTryGame}
                                  startDate={playtime.startDate}
                                  claimed={!!playtime.claimed}
                                  handlePlayGame={handlePlayGame}
                                  isDisabled={isLoading}
                                  validating={false}
                                />
                              </OfferTask>
                            )
                        )}
                        {visiblePlaytimes.length > 3 && (
                          <Typography variant="h2" sx={styles.moreText}>
                            and more!
                          </Typography>
                        )}
                      </>
                    ) : (
                      <div className="gameDetailsSpecialOfferPlaytimeRewardsCompletedContent">
                        <img src="/images/createAccountSliderImage4.png" />
                        <Typography variant="h4" sx={styles.completedText}>
                          You’re a gaming god! You’ve maxed out playtime rewards, but earn even more
                          when you spend in game !
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
                {/* Generic Offers */}
                {game?.specialOfferDetails?.offerType === OFFER_TYPE.GENERIC_OFFER && (
                  <GenericOffer
                    key={genericOffersKey.current}
                    title="Gameplay Rewards"
                    gameDetails={game}
                    playtimeBatchInfo={playtimeBatchInfo}
                    isGenericPlaytimeMMPOffer={isGenericPlaytimeMMPOffer}
                    onButtonClick={(taskType, taskDescription) => {
                      if (taskType === CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT) {
                        localStorage.setItem(
                          LOCAL_STORAGE.LAST_CLICKED_MMP_EVENT_TASK,
                          taskDescription
                        )
                      }
                      handlePlayGame()
                    }}
                    onRefresh={() => {
                      genericOffersKey.current = Date.now()
                      queryCLient.invalidateQueries('gameDetails')
                    }}
                  />
                )}
                {isOfferSpendingRewardsVisible && (
                  <OfferSpendingRewards
                    disabled={!isActivated || validating}
                    piggies={offer.piggyPerDollar}
                    coins={offer.coinsPerDollar}
                    finalCoins={Math.floor(offer.coinsPerDollar * eligibleSpendAmount)}
                    finalPiggies={Math.floor(offer.piggyPerDollar * eligibleSpendAmount)}
                    eligibleSpendAmount={calculateEligibleSpendAmount()}
                    showModal={showModal}
                    active={isActivated && !validating}
                  />
                )}
                {offer.mmpEventTasks?.length > 0 && (
                  <div className="gameDetailsSpecialOfferLoginRewards">
                    <Typography variant="h3">Gameplay Rewards</Typography>
                    {sortMMPEventTasks(offer.mmpEventTasks).map((event, index) => (
                      <OfferTask
                        key={`mmp event ${index}`}
                        disabled={
                          !isActivated ||
                          validating ||
                          checkIfMMPEventTaskUnlocked(offer.mmpEventTasks, event)
                        }
                        coins={event.rewards.coins}
                        piggies={event.rewards.piggyBanks}
                        title={event.taskDescription}
                        completed={offer.isInstallAttributed && event.completed && event.isUnlocked}
                        active={
                          isActivated &&
                          !validating &&
                          checkIfMMPEventTaskActive(offer.mmpEventTasks, event)
                        }
                        validationTimestamp={null}
                        expirationTimestamp={null}
                        icon={<img src={GamePad} />}
                        text={buildMMPEventTaskUnlockingRulesText(offer.mmpEventTasks, event)}
                        earn={true}
                      />
                    ))}
                  </div>
                )}
                {visibleDays?.length > 0 && game.specialOfferDetails.isUAOffer && (
                  <div className="gameDetailsSpecialOfferLoginRewards">
                    <Typography variant="h3">Login Rewards</Typography>

                    {visibleDays.map(
                      (day, key) =>
                        ((!loginRewardsExpanded && key === 0) || loginRewardsExpanded) && (
                          <OfferTask
                            disabled={!isActivated || validating}
                            key={`visible day ${day.day}`}
                            coins={day.rewards?.coins || 0}
                            piggies={day.rewards?.piggyBanks || 0}
                            title={'Play on Day ' + day.day}
                            completed={checkDayCompleted(day)}
                            active={day.startDate?._seconds < new Date().getTime() / 1000}
                            validationTimestamp={checkDayValidating(day) && day.updatedAt}
                            expirationTimestamp={getDayExpirationTimestamp(
                              day,
                              isActivated,
                              validating
                            )}
                            invalidateQueryKeys={invalidateQueryKeys}
                            icon={<img src={GamePad} />}
                            earn={true}
                          >
                            {checkDayValidating(day) && (
                              <Info onClick={() => handleShowValidatingModal(false)} />
                            )}
                            <Button
                              isActivated={isActivated}
                              day={day.day}
                              handleItemReward={handleItemReward}
                              handleTryGame={handleTryGame}
                              startDate={day.startDate}
                              claimed={checkDayCompleted(day)}
                              handlePlayGame={handlePlayGame}
                              isDisabled={isLoading}
                              validating={checkDayValidating(day)}
                            />
                          </OfferTask>
                        )
                    )}

                    {visibleDays.length > 1 && (
                      <BackArrow
                        customClassName={`gameDetailsSpecialOfferLoginRewardsArrow ${
                          loginRewardsExpanded ? 'expanded' : ''
                        }`}
                        onClick={handleClickLoginRewardsArrow}
                      />
                    )}
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </>
  )
}

const styles = {
  container: {
    padding: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: '#0F172A',
  },
  tags: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '14px 0 10px',
  },
  statement: {
    margin: 'auto 0',
  },
  spentText: {
    color: '#52525B',
    fontWeight: 500,
  },
  spentTextValue: {
    color: '#2D941C',
    fontWeight: 700,
  },
  earnText: {
    textAlign: 'right',
    padding: '10px',
  },
  button: {
    padding: '6px 14px',
    width: 'max-content',
    fontWeight: 700,
    fontFamily: 'var(--poppins-font)',
  },
  bottomButton: {
    padding: '16px 10px',
    fontWeight: 700,
  },
  earnTitle: {
    paddingTop: '16px',
  },
  moreText: {
    fontWeight: 700,
    textAlign: 'center',
  },
  completedText: {
    fontWeight: 700,
  },
  completed: {
    gap: '4px',
    display: 'flex',
  },
}

export default SpecialOffer
