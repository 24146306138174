import { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { decodeToken } from 'react-jwt'
import { useQuery } from 'react-query'
import { captureEvent } from '@sentry/react'
import posthog from 'posthog-js'

import AuthContext from '../context/AuthContext'
import useLocalStorage from '../hooks/useLocalStorage'
import { getUserById } from '../services/userService'
import { ADMIN_ROUTES, NOT_AUTHORIZED_ROUTES, PROTECTED_ROUTES, PUBLIC_ROUTES } from '../routes'
import useLoader from '../hooks/useLoader'
import {
  getUserPlatform,
  initializeTagManager,
  isRouteAvailable,
  sendRNMessage,
} from '../utils/utils'
import {
  LOCAL_STORAGE,
  ONBOARDING_STEP,
  ONBOARDING_STEPS,
  ONBOARDING_STEP_DIRECTION,
  ERRORS,
  REACT_NATIVE_STATUS,
  USER_PLATFORM,
} from '../constants'
import { handleLogoutBackend } from '../services/authService'
import PageInner from '../components/layout/PageInner'
import Layout from '../components/layout/Global'
import useMessageListener from '../hooks/useMessageListener'
import OnboardingLayout from '../components/layout/Onboarding'
import { changeOnboardingStep, interruptOnboarding } from '../services/onboardingService'
import HistoryContext from '../context/HistoryContext'
import { updateUserLastLoginData } from '../services/authService'
import { getGeoData } from '../services/geoDataService'

/**
 * Provider for AuthContext
 * Works for authorization, onboarding and all actions with user data
 *
 * @component
 * @param {ReactNode} children - Child component for rendering
 * @returns {ReactNode} A React element that renders loader or content with the context data
 */
const AuthProvider = ({ children }) => {
  const { getItem, removeItem, setItem } = useLocalStorage()
  const [user, setUser] = useState(null)
  const [isUserFetched, setUserFetched] = useState(false)
  const [onboardingStep, setOnboardingStep] = useState(null)
  const [onboardingVisible, setOnboardingVisible] = useState(false)
  const [isLoginDataUpading, setLoginDataUpdating] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()

  const { loader, showLoader, hideLoader } = useLoader(true)

  const navigate = useNavigate()

  const updateUserData = (user) => setUser(user)

  const { history } = useContext(HistoryContext)

  /**
   * A function for receiving user data by token. Takes the id from the token and sends a request to the backend to receive user data
   *
   * @function
   * @param {string} token - user jwt token
   * @param {boolean} isLogin - boolean field defines if it's a user login action
   * @returns {object | null} - user data or null, depending on the result of the request
   */
  const getUserFromToken = async (token, isLogin = false) => {
    const decodedToken = await decodeToken(token)

    return getUserById(decodedToken.id, isLogin)
  }

  /**
   * A function to change the onboarding step forward or backward and by a different number of steps depending on the received parameters
   *
   * @function
   * @param {boolean} next - checks forward or backward step changes
   * @param {number} steps - the value by which the current step changes
   * @returns {void}
   */
  const changeStep = async (next = true, steps = 1) => {
    const stepData = {
      direction: ONBOARDING_STEP_DIRECTION[next],
      count: steps,
    }
    return await changeOnboardingStep(stepData)
      .then((data) => {
        if (!data?.newStep) {
          return setOnboardingStep(null)
        }

        user.onboardingStep = data.newStep

        updateUserData(user)

        getOnboardingStep(user)
      })
      .catch(() => setOnboardingStep(null))
  }

  /**
   * A function to change the onboarding step and it's visibility when changing it in the user data or when changing the page
   *
   * @function
   * @param {object} user - user data
   * @returns {void}
   */
  const getOnboardingStep = (user) => {
    if (
      sessionStorage.getItem(LOCAL_STORAGE.ONBOARDING_INTERRUPT) &&
      user.onboardingStep !== ONBOARDING_STEP.COMPLETE
    ) {
      sessionStorage.removeItem(LOCAL_STORAGE.ONBOARDING_INTERRUPT)
      user.onboardingStep = ONBOARDING_STEP.COMPLETE
    }
    if (user?.onboardingStep) {
      let currentStep = null
      ONBOARDING_STEPS.map((step) => {
        if (step.name === user.onboardingStep) {
          currentStep = step
        }
      })

      document.body.className =
        currentStep &&
        !currentStep?.scrollable &&
        currentStep.content &&
        isRouteAvailable([currentStep])
          ? 'scroll-hidden'
          : ''

      setOnboardingVisible(currentStep && isRouteAvailable([currentStep]) && currentStep.content)
      return setOnboardingStep(currentStep)
    }

    setOnboardingStep(null)
  }

  useEffect(() => {
    if (user) {
      getOnboardingStep(user)
    }
  }, [user?.onboardingStep, location.pathname])

  const isProtectedRoute = isRouteAvailable(PROTECTED_ROUTES)
  const isNotAuthorizedRoute = isRouteAvailable(NOT_AUTHORIZED_ROUTES)
  const isPublicRoute = isRouteAvailable(PUBLIC_ROUTES)
  const isAdminRoute = isRouteAvailable(ADMIN_ROUTES)

  /**
   * Checks which type of page the user is on (public, protected, not authorized)
   *
   * @param {string} token - user jwt token
   * @returns {void}
   */
  const checkPage = (token) => {
    if (isPublicRoute) {
      return
    }

    if (!token && (isProtectedRoute || location.pathname === '/')) {
      navigate('/auth')
    }

    if (token && (isNotAuthorizedRoute || location.pathname === '/')) {
      navigate('/games')
    }

    if (isAdminRoute) {
      if (!token) {
        navigate('/games')
        return
      }
      const decodedToken = decodeToken(token)
      if (decodedToken.role !== 'Admin') {
        navigate('/games')
      }
    }
  }

  /**
   * Function for user authorization
   * Takes a jwt token from parameters or local storage checks it and, depending on the authorization result, changes the page for the user
   *
   * @function
   * @returns {void}
   */
  const authorizeUser = async () => {
    const paramsToken = searchParams.get(LOCAL_STORAGE.JWT_TOKEN)
    const storageToken = getItem(LOCAL_STORAGE.JWT_TOKEN)

    const token = storageToken || paramsToken

    checkPage(token)

    if (token) {
      await login(token)
    }

    if (searchParams.get(LOCAL_STORAGE.JWT_TOKEN)) {
      searchParams.delete(LOCAL_STORAGE.JWT_TOKEN)
      setSearchParams(searchParams)
    }
  }

  /**
   * Function to log out the user
   * Removes user context, clears local storage
   * Deletes all authorization data from third-party services and from the native app
   * And finally redirects the user to the authorization page
   *
   * @function
   * @returns {void}
   */
  const logout = async () => {
    setUser(null)
    removeItem(LOCAL_STORAGE.JWT_TOKEN)
    removeItem(LOCAL_STORAGE.IP_ADDRESS)
    posthog.reset(true)
    sendRNMessage({ status: REACT_NATIVE_STATUS.LOGOUT_USER })
    await handleLogoutBackend().catch((err) => {
      console.error(err)
    })
    hideLoader()
    navigate('/auth')
  }

  useEffect(() => {
    if (isUserFetched) {
      const token = getItem(LOCAL_STORAGE.JWT_TOKEN)

      checkPage(token)
    }
  }, [location.pathname])

  useQuery(['user'], async () => authorizeUser(user), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSettled: () => {
      setUserFetched(true)
      hideLoader()
    },
    onError: logout,
  })

  /**
   * Function for checking user data by jwt token
   * Receives the user data from the backend using a token, validates it, and stores the token and user data in the context depending on whether the data is valid
   *
   * @function
   * @param {string} token - user jwt token
   * @param {boolean} isLogin - boolean field defines if it's a user login action
   * @returns {object | null} - user data or null depending on the authorization result
   */
  const login = async (token, isLogin = false) => {
    try {
      const user = await getUserFromToken(token, isLogin)
        .then(async (user) => {
          if (!user) {
            logout()
            return
          }

          const ipGeolocationResponse = await getGeoData()

          const userGeoInfo = {
            ip: ipGeolocationResponse.data.ip,
            country: ipGeolocationResponse.data.country_code2,
          }

          setItem(LOCAL_STORAGE.JWT_TOKEN, token)

          if (isLogin) {
            const platform = getUserPlatform(USER_PLATFORM.DESKTOP)

            //If the user logs into the account from the web, do not update their platform.
            const lastLoginUpdateBody = {
              IPAddress: userGeoInfo.ip,
              ...(platform !== USER_PLATFORM.DESKTOP && { platform }),
            }
            const { updatedUser } = await updateUserLastLoginData(lastLoginUpdateBody)

            setUser({ ...user, platform: updatedUser.platform })
          } else {
            setUser(user)
          }

          if (user.userStatus === 'Blocked') {
            removeItem(LOCAL_STORAGE.JWT_TOKEN)
            return navigate('/blocked')
          }

          initializeTagManager(user.id)

          if (getItem(LOCAL_STORAGE.TUTORIAL_BEGIN)) {
            removeItem(LOCAL_STORAGE.TUTORIAL_BEGIN)
          }

          posthog.identify(user.id)
          return user
        })
        .catch((err) => {
          captureEvent({
            message: ERRORS.LOGIN,
            level: 'error',
            extra: { err: err.message },
          })
          logout()
        })
      setItem(LOCAL_STORAGE.LAST_ACTIVITY, new Date())
      return user
    } catch (error) {
      captureEvent({
        message: ERRORS.LOGIN,
        level: 'error',
        extra: { err: error.message },
      })
      console.log(error)
      logout()
    }
  }

  /**
   * A function for interrupting the onboarding data
   * Changes the user's onboarding step to the 'complete' and sends a request to the backend to interrupt the onboarding
   *
   * @function
   * @param {object} user - user data
   * @returns {void}
   */
  const handleInterruptOnboarding = async (user) => {
    user.onboardingStep = ONBOARDING_STEP.COMPLETE
    updateUserData(user)
    getOnboardingStep(user)
    await interruptOnboarding()
  }

  /**
   * Function to check if the user interrupted the onboarding
   * Checks if there is no user, user has reloaded the page or his onboarding steps is 'start' or 'complete'
   * Otherwise, it uses handleInterruptOnboarding function to interrupt onboarding
   * Also, if the user data is available and it is not a page reload, the last login changes the user data
   *
   * @function
   * @returns {void}
   */
  const handleCheckOnboardingInterrupt = async () => {
    if (
      !(getItem(LOCAL_STORAGE.JWT_TOKEN) || searchParams.get(LOCAL_STORAGE.JWT_TOKEN)) ||
      window.performance?.navigation?.type === 1 ||
      window.performance
        ?.getEntriesByType('navigation')
        ?.map((nav) => nav.type)
        ?.includes('reload')
    ) {
      return
    }

    if (
      user?.onboardingStep &&
      user.onboardingStep !== ONBOARDING_STEP.COMPLETE &&
      user.onboardingStep !== ONBOARDING_STEP.START
    ) {
      await handleInterruptOnboarding(user)
    }
  }

  useEffect(() => {
    if (isUserFetched) {
      if (user && location.pathname !== '/logout') {
        handleCheckOnboardingInterrupt().finally(() => setLoginDataUpdating(false))
      }

      setLoginDataUpdating(false)
    }
  }, [isUserFetched])

  useEffect(() => {
    /**
     * A function to interrupt the onboarding if the user clicked the back arrow in the browser on a step that does not allow it
     *
     * @function
     * @returns {void}
     */
    const handleBackButtonClick = () => {
      const currentPage = history[history.length - 1]

      if (currentPage && onboardingStep?.path && !isRouteAvailable([onboardingStep], currentPage)) {
        sessionStorage.setItem(LOCAL_STORAGE.ONBOARDING_INTERRUPT, true)
        handleInterruptOnboarding(user)
      }
    }

    window.addEventListener('popstate', handleBackButtonClick)
    return () => setTimeout(() => window.removeEventListener('popstate', handleBackButtonClick), 0)
  }, [onboardingStep])

  useMessageListener(showLoader, hideLoader, login, isUserFetched, user, updateUserData)

  const authState = { user, updateUserData, login, logout, changeStep, onboardingStep }

  if ((isProtectedRoute && !user) || loader || isLoginDataUpading) {
    return (
      <>
        {loader}
        <PageInner customClassName="blured" />
      </>
    )
  }

  return (
    <AuthContext.Provider value={authState}>
      {onboardingStep && onboardingVisible ? <OnboardingLayout step={onboardingStep} /> : null}
      <Layout user={user}>{children}</Layout>
    </AuthContext.Provider>
  )
}

export default AuthProvider
