import React, { useContext, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { useQueryClient } from 'react-query'

//contexts
import AuthContext from '../../../../../context/AuthContext'
//components
import OfferAlert from '../../../../../components/offerAlert'
//constants
import {
  CLAIM_SPECIAL_REWARD_REASON,
  INSTALL_VALIDATING_MINUTES,
  MILLISECONDS_IN_MINUTE,
  PLAY_DAY_VALIDATING_MINUTES,
} from '../../../../../constants'
//services
import { claimSpecialReward } from '../../../../../services/userRewardsService'
//utils
import { handleHeaderCounter } from '../../../../../utils/animations'
import { createVirtualCurrencyImagesArrays } from '../../../../../utils/utils'

const SpecialRewardAlert = ({ reward }) => {
  const { user, updateUserData } = useContext(AuthContext)

  const [coinsArray, setCoinsArray] = useState([])
  const [pigsArray, setPigsArray] = useState([])
  const [swiping, setSwiping] = useState(false)
  const [hidden, setHidden] = useState(false)

  const queryClient = useQueryClient()

  const checkValidation = () => {
    const rewardsToValidate = [
      CLAIM_SPECIAL_REWARD_REASON.INSTALL_GAME,
      CLAIM_SPECIAL_REWARD_REASON.PLAY_GAME,
    ]
    if (!rewardsToValidate.includes(reward.reason)) {
      return false
    }

    let validationTime
    switch (reward.reason) {
      case CLAIM_SPECIAL_REWARD_REASON.INSTALL_GAME:
        validationTime =
          process.env.REACT_APP_OFFER_INSTALL_VALIDATING_TIME ||
          INSTALL_VALIDATING_MINUTES * MILLISECONDS_IN_MINUTE
        break
      case CLAIM_SPECIAL_REWARD_REASON.PLAY_GAME:
        validationTime =
          process.env.REACT_APP_OFFER_PLAY_DAY_VALIDATING_TIME ||
          PLAY_DAY_VALIDATING_MINUTES * MILLISECONDS_IN_MINUTE
        break
    }
    return reward.earnedDate._seconds > (new Date().getTime() - validationTime) / 1000
  }

  const validating = checkValidation()

  const handleValidating = () => {
    if (!checkValidation() && validating) {
      queryClient.invalidateQueries({
        queryKey: 'specialRewards',
      })
    }
  }

  useEffect(() => {
    if (validating) {
      handleValidating()
      const interval = setInterval(handleValidating, 1000)

      return () => clearInterval(interval)
    }
  }, [validating])

  const handleClaim = (sourceDiv) => {
    createVirtualCurrencyImagesArrays(
      sourceDiv,
      reward.earnedCoins,
      reward.earnedPiggy,
      setCoinsArray,
      setPigsArray,
      0.5
    )

    handleHeaderCounter(false, reward.earnedCoins)
    handleHeaderCounter(true, reward.earnedPiggy)

    claimSpecialReward(reward.offerLisItemId, {
      reason: reward.reason,
      ...(reward.day && { day: reward.day }),
      ...(reward.offerSubId && { offerSubId: reward.offerSubId }),
    }).then(({ updatedUser }) => updateUserData(updatedUser))

    setTimeout(() => {
      setSwiping(true)
      setTimeout(() => setHidden(true), 1000)
    }, 1000)
  }

  const iconUrl =
    reward?.platformsIconUrl?.find((platform) => platform.os === user.platform.toLowerCase())
      ?.url || reward?.platformsIconUrl?.[0]?.url

  if (validating) {
    return null
  }

  const getOfferAlertTitle = (reward) => {
    switch (reward.reason) {
      case CLAIM_SPECIAL_REWARD_REASON.INSTALL_GAME:
        return `Played ${reward.gameName} for first 5 minutes!`
      case CLAIM_SPECIAL_REWARD_REASON.PLAYTIME:
        return `Played ${reward.minutes} minutes in game ${reward.gameName}!`
      case CLAIM_SPECIAL_REWARD_REASON.PLAY_GAME:
        return `Played ${reward.gameName} on day ${reward.day}!`
      case CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT:
        return reward.mmpEventTaskTitle
    }
  }

  return (
    <>
      <Typography variant="body2">{coinsArray}</Typography>
      <Typography variant="body2">{pigsArray}</Typography>
      <OfferAlert
        icon={<img src={iconUrl ? iconUrl : './images/complete.png'} style={{ padding: 3 }} />}
        title={getOfferAlertTitle(reward)}
        handleClick={(event) => handleClaim(event.currentTarget)}
        customClassName={`${swiping ? 'alertSwipe' : ''} ${hidden ? 'hidden' : ''}`}
        buttonText="Claim!"
        coins={reward.earnedCoins}
        piggies={reward.earnedPiggy}
        completed
      />
    </>
  )
}

export default SpecialRewardAlert
