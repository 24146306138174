import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import MessageLayout from '../components/layout/Message'

const CountryErrorPage = () => {
  const handleBackArrowClick = () => {
    window.location.href = `https://playbackrewards.com/`
  }

  const [searchParams] = useSearchParams()

  const [detectedCountry, setDetectedCountry] = useState(null)

  useEffect(() => {
    if (searchParams.has('detected-country')) {
      console.log('set country')
      setDetectedCountry(searchParams.get('detected-country'))
    }
  }, [])

  return (
    <MessageLayout title="Country Not Supported" handleBackArrowClick={handleBackArrowClick}>
      Unfortunately, Playback Rewards is not available in{' '}
      {detectedCountry ? detectedCountry : 'your country'} at this time.
    </MessageLayout>
  )
}

export default CountryErrorPage
