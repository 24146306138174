import React, { useContext, useEffect } from 'react'
import { Typography } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import MessageLayout from '../components/layout/Message'
import { handleStripeIdentity, sendRNMessage } from '../utils/utils'
import { MAX_ATTEMPTS_AMOUNT, REACT_NATIVE_STATUS, VERIFICATION_STATUS } from '../constants'
import AuthContext from '../context/AuthContext'

const NeedVerification = () => {
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    const { attempts, status } = user.identityVerification

    if (status === VERIFICATION_STATUS.VERIFIED) {
      navigate('/rewards')
    }
    if (
      attempts >= MAX_ATTEMPTS_AMOUNT ||
      status === VERIFICATION_STATUS.PLAYBACK_DUPLICATE_REJECTED
    ) {
      navigate('/verification/result')
    }
  }, [])

  const handleClick = async () => {
    if (window.ReactNativeWebView) {
      return sendRNMessage({ status: REACT_NATIVE_STATUS.CAMERA_PERMISSION })
    }

    handleStripeIdentity(user.id, navigate)
  }

  const handleBackArrowClick = () => {
    navigate('/rewards')
  }

  return (
    <MessageLayout
      title="Verify Your Identity"
      button={{
        text: 'Continue',
        handleClick,
      }}
      handleBackArrowClick={handleBackArrowClick}
    >
      <Typography variant="body2">
        The next couple of screens will require you to take a picture of yourself and of an ID card.
      </Typography>
      <Typography variant="body2">
        We do this to make sure you're a real human and not an evil robot trying to fake purchases.
      </Typography>
      <Typography variant="body2">
        We only use this to identify your identity and don’t store any pictures that you share.
      </Typography>
    </MessageLayout>
  )
}

export default NeedVerification
