import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { onValue, ref, off } from 'firebase/database'
import { DialogContent, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { decodeToken } from 'react-jwt'

import AuthContext from '../../../context/AuthContext'
import Modal from '../../modal'
import {
  INVALID_REFRESH_TOKEN_STATUS,
  LOCAL_STORAGE,
  REACT_NATIVE_STATUS,
} from '../../../constants'
import { db } from '../../../config/firebaseConfig'
import GoogleAuthButton from '../../auth/GoogleAuthButton'
import { handleConnectSecondGmail, sendRNMessage } from '../../../utils/utils'
import { updateRefreshTokenState } from '../../../services/authService'
import { getSecondEmails, getUserById } from '../../../services/userService'
import useLocalStorage from '../../../hooks/useLocalStorage'

function Layout({ user, children }) {
  const { updateUserData } = useContext(AuthContext)
  const [modal, setModal] = useState(false)
  const [modalProps, setModalProps] = useState([])

  const { getItem } = useLocalStorage()

  const handleClick = async () => {
    await updateRefreshTokenState(user.id)

    if (window.ReactNativeWebView) {
      setModal(false)
      const token = await getItem(LOCAL_STORAGE.JWT_TOKEN)
      const decodedToken = decodeToken(token)
      sendRNMessage({ status: REACT_NATIVE_STATUS.CONNECT_SECOND, userId: decodedToken.id })
      return
    }
    handleConnectSecondGmail(user.id, 'auth/gmail/second-email')
  }

  const showModal = (modalProps) => {
    setModalProps(modalProps)
    setModal(true)
  }
  const hideModal = async () => {
    updateRefreshTokenState(user.id)
    setModal(false)
  }

  const actionButtons = (
    <div className="modalButtons">
      <GoogleAuthButton handleClick={handleClick} text="Connect with Google" />
      <Typography variant="h4">
        <Link to="/faq?shown=7" className="connectGmailModalMore" onClick={hideModal}>
          Learn more...
        </Link>
      </Typography>
    </div>
  )

  useEffect(() => {
    if (user) {
      const invalidRefreshTokenRef = ref(db, `invalid_refresh_token/${user.id}`)

      const invalidRefreshTokenHandler = async (snapshot) => {
        const tokenStatus = snapshot.val()
        if (_.get(tokenStatus, 'state', '') === INVALID_REFRESH_TOKEN_STATUS.INVALID) {
          getSecondEmails().then((data) => {
            const emails = data.reduce((total, email) => {
              if (email.grantNotification) {
                total.push(email.connectedEmail)
              }
              return total
            }, [])

            showModal({
              title: 'Gmail Access Required',
              text: (
                <DialogContent>
                  <Typography variant="body2">
                    Google requires access to gmail to be renewed periodically. Playback can't
                    detect or reward new receipts until this is done.
                  </Typography>
                  {emails.length > 0 && (
                    <>
                      <Typography variant="body2" sx={styles.modalEmailsTitle}>
                        <br />
                        Emails you need to connect:
                      </Typography>
                      {emails.map((email) => (
                        <Typography key={email} variant="h4" sx={styles.modalEmails}>
                          {email}
                        </Typography>
                      ))}
                    </>
                  )}
                </DialogContent>
              ),
              actionButtons: actionButtons,
              isCustomText: true,
            })
          })
        }
      }

      onValue(invalidRefreshTokenRef, invalidRefreshTokenHandler)

      return () => {
        off(invalidRefreshTokenRef, invalidRefreshTokenHandler)
      }
    }
  }, [user])

  useEffect(() => {
    if (user) {
      const piggyChestRef = ref(db, `piggy_chest_refresh/${user.id}`)

      const updateUserState = async () => {
        const data = await getUserById(user.id)
        return updateUserData(data)
      }

      onValue(piggyChestRef, updateUserState)
    }
  }, [])

  return (
    <>
      <Modal showModal={modal} hideModal={hideModal} {...modalProps} />
      {children}
    </>
  )
}

const styles = {
  modalEmailsTitle: {
    fontWeight: 600,
  },
  modalEmails: {
    fontWeight: 500,
    textAlign: 'center',
  },
}

export default Layout
