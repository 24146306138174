import { Alert, Typography } from '@mui/material'

//components
import BottomButton from 'components/common/BottomButton'
import Timer from 'components/timer'
import ValueFrame from 'components/valueFrame'
//utils
import { CheckMark } from 'utils/icons'
//styles
import './index.css'
//types
import { OfferAlertProps } from 'types'

const OfferAlert = ({
  title,
  children,
  handleClick,
  icon,
  buttonText,
  customClassName,
  piggies,
  coinsFly,
  coins,
  completed,
  isModal = false,
  timer,
}: OfferAlertProps) => {
  const action = (
    <div className="actionContainer">
      {timer && <Timer isActive={true} time={timer} title="" />}
      <div className="rewardsAndButtonConatiner">
        {piggies > 0 && (
          <ValueFrame value={piggies} isPig={true} isModal={isModal} coinsFly={coinsFly} />
        )}
        {coins > 0 && <ValueFrame value={coins} isModal={isModal} coinsFly={coinsFly} />}
        {buttonText && (
          <BottomButton className="actionButton" handleClick={handleClick}>
            {buttonText}
          </BottomButton>
        )}
      </div>
    </div>
  )

  return (
    <Alert
      className={`offerAlert ${isModal ? 'modal' : ''} ${customClassName}`}
      severity="info"
      icon={icon}
      action={action}
    >
      <div className="offerAlertContent">
        <Typography variant="h4" className="title">
          {title}
        </Typography>

        {children && (
          <Typography variant="body2" className="children">
            {children}
          </Typography>
        )}
        {completed && (
          <Typography variant="body2" className="complete">
            <CheckMark />
            Completed
          </Typography>
        )}
      </div>
    </Alert>
  )
}

export default OfferAlert
