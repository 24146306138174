import { useMemo } from 'react'

//assets
import GamePad from 'assets/offers/gamepad.png'
//components
import Offer from 'components/Offer'
//types
import { GenericMMPTaskProps } from 'types'

const GenericMMPTask = ({
  isInstalled,
  allMMPTasks,
  mmpTask,
  buttonText,
  onButtonClick,
}: GenericMMPTaskProps) => {
  const hasPreviousRequirements = useMemo(() => {
    return mmpTask.previousTaskRequirements
      .map((requirement) =>
        allMMPTasks.find((event) => event.taskId === requirement && !event.completed)
      )
      .filter((event) => event !== undefined)
  }, [mmpTask.previousTaskRequirements])
  const isLocked = useMemo(() => hasPreviousRequirements.length > 0, [hasPreviousRequirements])
  const subTitle = useMemo(() => {
    if (!isInstalled) {
      return 'Unlocks after install'
    } else if (hasPreviousRequirements) {
      return hasPreviousRequirements.map((requirement, index) => (
        <p key={`requirement ${requirement?.taskId}-${index}`}>
          Unlocks after {requirement?.taskDescription}
        </p>
      ))
    }
    return ''
  }, [isInstalled, hasPreviousRequirements])

  return (
    <Offer
      icon={<img src={GamePad} />}
      title={mmpTask.taskDescription}
      subTitle={subTitle}
      piggies={mmpTask.rewards.piggyBanks}
      coins={mmpTask.rewards.coins}
      isCompleted={mmpTask.completed}
      isLocked={!isInstalled || isLocked}
      buttonText={buttonText}
      onButtonClick={onButtonClick}
    />
  )
}

export default GenericMMPTask
