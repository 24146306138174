import { createContext } from 'react'

const AuthContext = createContext({
  user: null,
  onboardingStep: null,
  updateUserData: () => {},
  login: () => {},
  logout: async () => {},
  changeStep: async () => {},
})

export default AuthContext
