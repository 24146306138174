import React, { useMemo } from 'react'

//constants
import { CLAIM_SPECIAL_REWARD_REASON } from 'constants/index'
//components
import GenericMMPTask from './GenericMMPTask'
import GenericPlaytimeTask from './GenericPlaytimeTask'
//types
import { GenericPlaytimeMMPTaskProps } from 'types'
//utils
import { isLockedMMPTask, checkIfPlaytimeTaskCompleted } from 'utils/genericOffer'

const GenericPlaytimeMMPTask = ({
  isInstalled,
  batchPlaytimeConfig,
  batches,
  playtimeBatchInfo,
  activeIndex,
  allPlaytimeTasksCompleted,
  mmpEventTasks,
  onButtonClick,
  onRefresh,
}: GenericPlaytimeMMPTaskProps) => {
  const unlockedMMPTasks = useMemo(
    () => mmpEventTasks.filter((task) => !isLockedMMPTask(task, mmpEventTasks)),
    [mmpEventTasks, isLockedMMPTask]
  )
  const lockedMMPTasks = useMemo(
    () => mmpEventTasks.filter((task) => isLockedMMPTask(task, mmpEventTasks) || !isInstalled),
    [mmpEventTasks, isLockedMMPTask, isInstalled]
  )
  const isPlaytimeTaskCompleted = useMemo(
    () => checkIfPlaytimeTaskCompleted(batchPlaytimeConfig, playtimeBatchInfo, activeIndex),
    [batchPlaytimeConfig, playtimeBatchInfo, activeIndex]
  )

  return (
    <>
      {/* Unlocked Generic Playtime Tasks*/}
      {batchPlaytimeConfig &&
        !allPlaytimeTasksCompleted &&
        activeIndex > -1 &&
        !isPlaytimeTaskCompleted && (
          <GenericPlaytimeTask
            key={`playtime-${activeIndex}`}
            index={activeIndex}
            activeIndex={activeIndex}
            batchPlaytimeConfig={batchPlaytimeConfig}
            playtimeBatchInfo={playtimeBatchInfo}
            onRefresh={onRefresh}
            buttonText="Play"
            onButtonClick={() => onButtonClick(CLAIM_SPECIAL_REWARD_REASON.PLAYTIME)}
          />
        )}
      {/* Unlocked Generic MMP Tasks*/}
      {isInstalled &&
        unlockedMMPTasks?.map((task, index) => {
          return (
            <GenericMMPTask
              key={`mmpevent-${task.taskId}-${index}`}
              isInstalled={isInstalled}
              allMMPTasks={mmpEventTasks}
              mmpTask={task}
              buttonText={index === 0 && isPlaytimeTaskCompleted ? 'Play' : undefined}
              onButtonClick={() =>
                onButtonClick(CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT, task.taskDescription)
              }
            />
          )
        })}
      {/* Locked Generic Playtime Tasks*/}
      {batchPlaytimeConfig &&
        !allPlaytimeTasksCompleted &&
        batches.map((_, index) => {
          return (
            index > activeIndex &&
            index <= activeIndex + 1 && (
              <GenericPlaytimeTask
                key={`playtime-${index}`}
                index={index}
                activeIndex={activeIndex}
                batchPlaytimeConfig={batchPlaytimeConfig}
                playtimeBatchInfo={playtimeBatchInfo}
                onRefresh={onRefresh}
              />
            )
          )
        })}
      {/* Locked Generic MMP Tasks*/}
      {lockedMMPTasks?.map((task, index) => {
        return (
          <GenericMMPTask
            key={`mmpevent-${task.taskId}-${index}`}
            isInstalled={isInstalled}
            allMMPTasks={mmpEventTasks}
            mmpTask={task}
          />
        )
      })}
    </>
  )
}

export default GenericPlaytimeMMPTask
