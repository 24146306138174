import { useEffect, useMemo, useState } from 'react'
import { getMinutesInBatch } from '@playback-rewards/shared-libraries'
import { DateTime, Duration } from 'luxon'

//assets
import GamePad from 'assets/offers/gamepad.png'
import Timer from 'assets/offers/timer.png'
//components
import Offer from 'components/Offer'
//types
import { GenericPlaytimeTaskProps } from 'types'
//utils
import { getTimeDifference, getTimeValue } from 'utils/genericOffer'

const GenericPlaytimeTask = ({
  index,
  activeIndex,
  batchPlaytimeConfig,
  playtimeBatchInfo,
  onRefresh,
  buttonText,
  onButtonClick,
  hideUI = false,
}: GenericPlaytimeTaskProps) => {
  const [time, setTime] = useState<Duration>()
  const isLocked = useMemo(() => index > activeIndex, [index, activeIndex])
  const minutesInBatch = getMinutesInBatch(batchPlaytimeConfig) || 0
  const remainingMinutes = Math.ceil(playtimeBatchInfo?.getRemainingMinutesInBatch(index) || 0)
  const progress = useMemo(
    () => Math.round(((minutesInBatch - remainingMinutes) / minutesInBatch) * 100),
    [minutesInBatch, remainingMinutes]
  )
  const isCompleted = useMemo(() => !isLocked && progress >= 100, [isLocked, progress])
  useEffect(() => {
    if (
      !playtimeBatchInfo?.activatedOffer.mmpAttributionRequiredForRewards ||
      !playtimeBatchInfo?.activatedOffer.isInstallAttributed
    ) {
      return
    }
    const batchStartTime = playtimeBatchInfo?.getBatchStartTime(index) || DateTime.now().toJSDate()
    const batchEndTime = playtimeBatchInfo?.getBatchEndTime(index) || DateTime.now().toJSDate()
    const luxonStartTime = DateTime.fromJSDate(batchStartTime)
    const luxonbBatchEndTime = DateTime.fromJSDate(batchEndTime)
    const luxonCurrentTime = DateTime.now()
    const diff = getTimeDifference(
      !isLocked ? luxonbBatchEndTime : luxonStartTime,
      luxonCurrentTime
    )
    setTime(diff)
    const interval = setInterval(() => {
      const luxonCurrentTime = DateTime.now()
      const diff = getTimeDifference(
        !isLocked ? luxonbBatchEndTime : luxonStartTime,
        luxonCurrentTime
      )
      if (diff.valueOf() <= 0) {
        clearInterval(interval)
        return onRefresh()
      }
      setTime(diff)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [isLocked, playtimeBatchInfo])
  const subTitle = useMemo(() => {
    if (
      !playtimeBatchInfo?.activatedOffer.mmpAttributionRequiredForRewards ||
      !playtimeBatchInfo?.activatedOffer.isInstallAttributed ||
      isCompleted
    ) {
      return
    }

    return isLocked ? (
      <span>
        Unlocks in: <img src={Timer} /> {getTimeValue(time)}
      </span>
    ) : (
      <span>
        {remainingMinutes} {remainingMinutes > 1 ? 'minutes' : 'minute'} remaining
      </span>
    )
  }, [time])

  if (hideUI) {
    return <></>
  }

  return (
    <Offer
      icon={<img src={GamePad} />}
      title={`Play ${minutesInBatch} minutes`}
      subTitle={subTitle}
      piggies={batchPlaytimeConfig?.pigsPerBatch || 0}
      coins={batchPlaytimeConfig?.coinsPerBatch || 0}
      progress={progress}
      isCompleted={isCompleted}
      isLocked={isLocked}
      time={time?.toFormat('hh:mm:ss')}
      showTime={!isLocked && !isCompleted}
      buttonText={buttonText}
      onButtonClick={onButtonClick}
    />
  )
}

export default GenericPlaytimeTask
