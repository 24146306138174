import toast from 'react-hot-toast'
import { ERROR_MESSAGE, ERROR_TEXT, LOCAL_STORAGE } from '../constants'

export const errorHandler = {
  OFFER_REDIRECT: (message) => {
    toast.error(message)
    setTimeout(() => {
      window.location.href = '/games'
    }, 2000)
  },
  OFFER_NOTIFICATION: () => toast.error(ERROR_MESSAGE),
  USER_NOTIFICATION: (message) => toast.error(message),
  VALIDATION_NOTIFICATION: (message) => toast.error(message),
  VALIDATION_REDIRECT: (message, additionalParams) => {
    const getDestinationUrl = {
      [ERROR_TEXT.unsupported_country]: `/country-error?detected-country=${additionalParams.country}`,
      [ERROR_TEXT.connected_email]: `/auth-error?message=${message}`,
    }

    window.location.href = getDestinationUrl[message]
  },
  TECHNICAL_REDIRECT: () => {
    localStorage.removeItem(LOCAL_STORAGE.JWT_TOKEN)
    window.location.href = '/not-available'
  },
  QUEST_NOTIFICATION: (message) => toast.error(message),
  AUTH_REDIRECT: () => {
    localStorage.removeItem(LOCAL_STORAGE.JWT_TOKEN)
    window.location.href = '/auth'
  },
  REFRESH_APP_CHECK: async () => {
    // const appCheckToken = await getToken(appCheck)
    // localStorage.setItem(LOCAL_STORAGE.APP_CHECK_TOKEN, appCheckToken.token)
  },
  PIGGY_BANK_CHEST_NOTIFICATION: (message) => toast.error(message),
}
