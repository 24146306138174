import axios from 'axios'
import { APP_PLATFORM, LOCAL_STORAGE } from './constants'
import { errorInterceptorHandler, generateId } from './utils/utils'

export const axiosBackendInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  timeout: 10000,
  withCredentials: true,
})

// export const axiosPrivate = axios.create({
//   baseURL: `${process.env.REACT_APP_SERVER_URL}`,
//   timeout: 10000,
// })

axiosBackendInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(LOCAL_STORAGE.JWT_TOKEN)

  // await getToken(appCheck, true)
  //   .then(({ token }) => {
  //     config.headers['x-firebase-appcheck'] = token
  //   })
  //   .catch((err) => {
  //     captureEvent({
  //       message: ERRORS.APP_CHECK,
  //       level: 'error',
  //       extra: { err: err.message },
  //     })
  //     window.dispatchEvent(initAppCheck)
  //   })

  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }

  config.headers['x-platform'] = window.ReactNativeWebView
    ? APP_PLATFORM.NATIVE_APP
    : APP_PLATFORM.WEB
  config.headers['x-request-id'] = generateId(12)

  return config
})

axiosBackendInstance.interceptors.response.use((response) => response.data, errorInterceptorHandler)
